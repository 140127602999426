import { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSearchParam } from "react-use";
import { useSearch } from "context/SearchContext";
import { pages } from "routers/pages";
import { MOMENT_FRENCH_I18N } from "utils/moment.utils";
import ScrollToTop from "routers/ScrollToTop";
import Page404 from "containers/Page404/Page404";
import moment from "moment";

moment.locale("fr", MOMENT_FRENCH_I18N);

function App() {
  const { myHotelInformation } = useSearch();
  const key = useSearchParam("key");

  useEffect(() => {
    const storageKey = localStorage.getItem("key");
    if (key && !storageKey) {
      localStorage.setItem("key", key);
    }

    const root = document?.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
  }, []);

  return (
    <div className='text-base dark:bg-neutral-black text-neutral-black dark:text-primary-gray'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{myHotelInformation?.name}</title>
        <meta name='description' content={myHotelInformation?.description} />
        <link rel='icon' href={myHotelInformation?.logo} />
        <link rel='apple-touch-icon' href={myHotelInformation?.logo} />
      </Helmet>
      <BrowserRouter basename='/'>
        <ScrollToTop />
        {/* <SiteHeader /> */}

        <Switch>
          {pages.map(({ component, path, exact }) => {
            return <Route key={path} component={component} exact={!!exact} path={path} />;
          })}
          <Route component={Page404} />
        </Switch>

        {/* {WIN_WIDTH < 768 && <FooterNav />} */}
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
