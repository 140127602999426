import React, { FC, useEffect, useRef, useState } from "react";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StartRating from "components/StartRating/StartRating";
import moment from "moment";
import { useSearchParam } from "react-use";
import { addLetterSofPlural } from "utils/string";
import { useParams } from "react-router-dom";
import Select from "shared/Select/Select";
import Checkbox from "shared/Checkbox/Checkbox";
import MobileFooterSticky from "./MobileFooterSticky";
import Preloader from "components/Preloader";
import Page404 from "containers/Page404/Page404";
import { getBookingDetailsService, postCreateBokingService } from "api.service";
import { PostCreateBokingService } from "interfaces/booking";
import { IPostInitBookingServiceResponse } from "interfaces/booking";
import BecomeAnAuthorImg from "images/BecomeAnAuthorImg.png";
import converSelectedDateToString from "utils/converSelectedDateToString";
import Textarea from "shared/Textarea/Textarea";
import { getCountries, getCountryCallingCode } from "react-phone-number-input/input";
// @ts-ignore
import countryFrLang from "react-phone-number-input/locale/fr";
import { CountryCode } from "libphonenumber-js/types";
import PayPage from "containers/PayPage/PayPage";
import { useSearch } from "context/SearchContext";
import NcImage from "shared/NcImage/NcImage";
import masterCardImage from "images/MasterCard.png.webp";
import eDinarImage from "images/e-dinar.webp";
import visaCardImage from "images/VISA.gif";
import Footer from "shared/Footer/Footer";
import BasicHeader from "components/BasicHeader";
import ReCAPTCHA from "react-google-recaptcha"

export interface CheckOutPageProps {
  className?: string;
}

const useFetchBookingDetails = (): {
  data?: IPostInitBookingServiceResponse;
  loading: Boolean;
  isError: Boolean;
  setData: (data: IPostInitBookingServiceResponse) => void;
} => {
  const [data, setData] = useState<IPostInitBookingServiceResponse>();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const slugId = useSearchParam("checkoutSlug") || "";
  const reservationId = useSearchParam("reservation_id") || "";
  const slug = slugId || reservationId;

  const { id }: { id: string } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await getBookingDetailsService(slug || id);
        if (res.slug) {
          setData(res);
        } else {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
      }
      setLoading(false);
    })();
  }, []);

  return {
    data,
    loading,
    isError,
    setData(data) {
      setData(data);
    },
  };
};

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const [isAcceptedTermsAndConditions, setIsAcceptedTermsAndConditions] = useState<boolean>(false);
  const [isPaymentMethodError, setIsPaymentMethodError] = useState(false);
  const [customerData, setCustomerData] = useState<
    PostCreateBokingService["customer"]["reservation_details"]
  >({});
  const [isReservationDone, setIsReservationDone] = useState(false);
  const [isReservationFailed, setIsReservationFailed] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false)
  const { data, loading, isError, setData } = useFetchBookingDetails();
  const { searchData } = useSearch()
  const [generalInformationData, setGeneralInformationData] = useState<{
    name?: string;
    email?: string;
    phone?: string;
    country?: string;
  }>({ country: getCountryCallingCode("TN") });
  const [commentValue, setCommentValue] = useState("");
  const [createBookingLoading, setCreateBookingLoading] = useState(false);
  const captchaRef = useRef<any>()

  const hotelDetails = data?.hotel;
  const selectedDate = {
    startDate: moment(data?.start_date),
    endDate: moment(data?.end_date),
  };

  const disabledSubmitButton = !(isCaptchaVerified && isAcceptedTermsAndConditions)

  const onChangeCustomerData = (
    roomIndex: number,
    customerIndex: number,
    type: string,
    value: string
  ) => {
    setCustomerData({
      ...customerData,
      [roomIndex]: {
        ...(customerData?.[roomIndex] || {}),
        [customerIndex]: {
          ...(customerData?.[roomIndex]?.[customerIndex] || {}),
          [type]: value,
        },
      },
    });
  };


  const onChangeGeneralInformation =
    (key: keyof typeof generalInformationData) =>
      (e?: React.ChangeEvent<HTMLInputElement>, value?: string) =>
        setGeneralInformationData({
          ...generalInformationData,
          [key]: value || e?.target.value,
        });

  const renderSidebar = () => {
    return (
      <div className='sticky top-0 px-2 py-16 lg:py-0 w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-3 lg:px-0 '>
        <div className='flex-shrink-0 w-full '>
          <div className='w-full rounded-2xl h-72 '>
            <img alt='' className='h-full w-auto m-auto rounded-t-2xl' src={hotelDetails?.cover} />
          </div>
        </div>
        <div className='px-5 pb-5 space-y-6'>
          <div className='font-bold text-2xl flex items-center'>
            <div className='mr-2'>{hotelDetails?.name}</div>
            <StartRating
              starClassName='w-[25px] h-[25px]'
              reviewCount={0}
              point={hotelDetails?.stars}
            />
          </div>
          <div className='flex justify-between text-sm'>
            <span>Ville </span> <b>{myHotelInformation?.city?.city || "-"}</b>
          </div>

          <div className='flex justify-between text-sm'>
            <span>Adresse </span> <b>{hotelDetails?.address}</b>
          </div>

          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4'></div>
          <div className='font-bold mb-2'>Détails du prix</div>
          {Object.entries(data?.details || {}).map(([idx, room]) => {
            return (
              <div className='' key={idx}>
                <div className='flex justify-between'>
                  <div className='text-sm font-bold'>{room?.room?.name}</div>
                  <div className='flex items-center space-x-2'>
                    {room?.price?.initial_price && room.price?.initial_price !== room?.price?.price && (
                      <div className='text-sm line-through text-red-600'>
                        {room?.price?.initial_price} {searchData?.currency}
                      </div>
                    )}
                    <b>{room?.price?.price} {searchData?.currency}</b>
                  </div>
                </div>

                <div className='text-sm'>
                  <div className=''>
                    {room.nb_adult} adulte{addLetterSofPlural(room.nb_adult)}
                    {room.child_ages.length ? `, ${room.child_ages.length} enfant` : ""}
                    {room.baby_ages.length ? `, ${room.baby_ages.length} bébé` : ""}
                  </div>
                  <div className=''>
                    {room.arrangement.name}
                  </div>
                </div>
              </div>
            );
          })}

          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 '></div>
          <div className='flex justify-between'>
            <div className='font-bold text-xl'>Total</div>
            <div className='flex items-center'>
              {/* {calculateTotalPrice().price !==
                calculateTotalPrice().price_without_promotion && (
                <div className="font-normal mr-2 text-sm text-red-600 line-through ">
                  {calculateTotalPrice().price_without_promotion} {searchData?.currency}
                </div>
              )} */}
              <div className='font-bold text-xl uppercase'>
                {data?.price_details.total_price} {data?.price_details.currency}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderGuestsInformation = (
    columns?: {
      id: string;
      title: string;
    }[]
  ) => {
    return (
      <>
        {Object.entries(data?.details || {}).map(([roomIndex, room], idx) => {
          return (
            <div
              className='mb-5 w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 lg:space-y-8 space-y-5 px-0 sm:p-6 xl:p-8'
              key={idx}>
              {/* <div className="lg:text-xl sm:text-base mb-2  text-primary-blue font-bold ">
          Liste des chambres choisies
        </div> */}
              <div className=''>
                <h2 className='lg:text-xl sm:text-base text-primary-blue font-bold capitalize'>
                  <div className='lg:text-lg sm:text-sm text-sm'>{room.room.name}</div>
                </h2>
                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 lg:my-5 my-3'></div>

                <div className='text-sm lg:text-base'>
                  {room.nb_adult} Adulte{addLetterSofPlural(room.nb_adult)}
                  {room.child_ages.length
                    ? `, ${room.child_ages.length} Enfant${addLetterSofPlural(
                      room.child_ages.length
                    )}`
                    : ""}
                  {room.baby_ages.length
                    ? `, ${room.baby_ages.length} Bébé${addLetterSofPlural(room.baby_ages.length)}`
                    : ""}
                </div>

                <div className='text-sm lg:text-base'>
                  {room.arrangement.name}
                </div>

                <div className='w-14 border-b border-neutral-200 dark:border-neutral-700 lg:my-5 my-3'></div>
                <div className='text-sm lg:text-base'>
                  {Array.from({ length: room.nb_adult }).map((room, index) => (
                    <div key={index}>
                      <div className='p-2 font-bold table-cell'>Adulte {index + 1}</div>
                      <div className='flex space-between w-full flex-wrap'>
                        <div className='p-2 pl-0 w-full'>
                          <Select
                            onChange={e =>
                              onChangeCustomerData(
                                Number(roomIndex),
                                index + 1,
                                "gender",
                                e.target.value
                              )
                            }
                            placeholder='Sélectionner'
                            required>
                            <option value=''>Sélectionner</option>
                            <option value='M.'>M.</option>
                            <option value='Mme.'>Mme.</option>
                          </Select>
                        </div>
                        <div className='p-2 pl-0 w-full'>
                          <Input
                            placeholder='Prénom'
                            onChange={e =>
                              onChangeCustomerData(
                                Number(roomIndex),
                                index + 1,
                                "first_name",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                        <div className='p-2 pl-0 w-full'>
                          <Input
                            placeholder='Nom'
                            onChange={e =>
                              onChangeCustomerData(
                                Number(roomIndex),
                                index + 1,
                                "last_name",
                                e.target.value
                              )
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {room.child_ages.map((age, index) => (
                    <div key={index}>
                      <div>
                        <div className='p-2 '>
                          <div className='table-cell font-bold'>
                            Enfant ( {age} an
                            {addLetterSofPlural(age)} )
                          </div>
                        </div>
                        <div className='flex justify-between flew-wrap'>
                          <div className='p-2 pl-0 w-full'>
                            <Input
                              className='w-full'
                              placeholder='Prénom'
                              onChange={e =>
                                onChangeCustomerData(
                                  Number(roomIndex),
                                  room.nb_adult + index + 1,
                                  "first_name",
                                  e.target.value
                                )
                              }
                              required
                            />
                          </div>
                          <div className='p-2 pl-0 w-full'>
                            <Input
                              placeholder='Nom'
                              className='w-full'
                              onChange={e =>
                                onChangeCustomerData(
                                  Number(roomIndex),
                                  room.nb_adult + index + 1,
                                  "last_name",
                                  e.target.value
                                )
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {room.baby_ages.map((age, index) => (
                    <div key={index}>
                      <div>
                        <div className='p-2 '>
                          <div className='table-cell font-bold'>
                            Bébé ( {age || "0-1"} an
                            {addLetterSofPlural(age)} )
                          </div>
                        </div>
                        <div className='flex justify-between flew-wrap'>
                          <div className='p-2 pl-0 w-full'>
                            <Input
                              className='w-full'
                              placeholder='Prénom'
                              onChange={e =>
                                onChangeCustomerData(
                                  Number(roomIndex),
                                  room.nb_adult + (room.child_ages.length || 0) + index + 1,
                                  "first_name",
                                  e.target.value
                                )
                              }
                              required
                            />
                          </div>
                          <div className='p-2 pl-0 w-full'>
                            <Input
                              placeholder='Nom'
                              className='w-full'
                              onChange={e =>
                                onChangeCustomerData(
                                  Number(roomIndex),
                                  room.nb_adult + (room.child_ages.length || 0) + index + 1,
                                  "last_name",
                                  e.target.value
                                )
                              }
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const generalInformation = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 lg:space-y-8 space-y-5 px-0 sm:p-6 xl:p-8'>
        <div className='lg:text-lg sm:text-sm text-sm mb-2  text-primary-blue font-bold '>
          Votre séjour
        </div>
        <div className=''>
          Dates : <span className='font-bold'>{converSelectedDateToString(selectedDate)}</span>
        </div>
        <div className=''>
          Occupation :{" "}
          <span className='font-bold'>
            {Object.keys(data?.details || {}).length} chambre
            {addLetterSofPlural(Object.keys(data?.details || {}).length)} / {data?.pax?.nb_person}{" "}
            personne
            {addLetterSofPlural(data?.pax?.nb_person || 0)}
          </span>
        </div>
        <div className='  flex-wrap lg:flex-nowrap  space-y-4 lg:space-y-4 '>
          <Input
            value={generalInformationData.name}
            required
            placeholder='Nom et Prénom'
            className='w-full'
            onChange={onChangeGeneralInformation("name")}
          />
          <Input
            value={generalInformationData.email}
            required
            placeholder='Email'
            type='email'
            className='w-full'
            onChange={onChangeGeneralInformation("email")}
          />
          <div className=''>
            <Select
              placeholder='Numéro de téléphone'
              className=' rounded-b-none '
              defaultValue={"TN"}
              onChange={e =>
                onChangeGeneralInformation("country")(
                  undefined,
                  getCountryCallingCode(e.target.value as CountryCode)
                )
              }>
              {getCountries().map(country => (
                <option key={country} value={country}>
                  {countryFrLang[country]} +{getCountryCallingCode(country)}
                </option>
              ))}
            </Select>
            <Input
              value={generalInformationData.phone}
              placeholder='Numéro de téléphone'
              type='number'
              className=' rounded-t-none '
              onChange={onChangeGeneralInformation("phone")}
              required
            />
          </div>
        </div>
      </div>
    );
  };

  const paymentMethod = () => {

    function onChange() {
      setIsCaptchaVerified(true)
    }
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 lg:space-y-8 space-y-5 px-0 sm:p-6 xl:p-8'>
        <div className='lg:text-lg sm:text-sm text-sm mb-2  text-primary-blue font-bold '>
          Modalité de paiement
        </div>

        {Number(data?.price_details.total_price) ===
          Number(data?.price_details.online_amount_to_pay) ? (
          <div className=''>
            Je paie le montant total <u>en ligne</u>{" "}
            <b>{Number(data?.price_details.amount_to_pay)} {searchData?.currency}</b>
          </div>
        ) : (
          <div className=''>
            Je paie une avance de <b>{data?.price_details.online_amount_to_pay} {searchData?.currency}</b>{" "}
            <u>en ligne</u> et le reste{" "}
            <b>{Number(data?.price_details.amount_left_to_pay || 0)} {searchData?.currency}</b> à l'établissement
          </div>
        )}

        <div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY || ""}
            onChange={onChange}
            ref={captchaRef}
          />
        </div>

        <Checkbox
          name='term and conditions'
          className='items-center'
          labelClassName='text-sm lg:text-sm'
          inputClassName='w-4 h-4'
          onChange={() => {
            setIsPaymentMethodError(false);
            setIsAcceptedTermsAndConditions(v => !v);
          }}
          checked={isAcceptedTermsAndConditions}
          label={
            "J'accepte les conditions spécifiques de cette réservation et Les conditions générales de vente et j'y adhère sans aucune réserve"
          }
        />

        {isPaymentMethodError && (
          <div className='text-red-500'>Veuillez accepter les conditions</div>
        )}

        <div className='flex items-center'>
          <NcImage className='h-10' src={visaCardImage} />
          <NcImage className='h-10 mx-4' src={masterCardImage} />
          <NcImage className='h-8' src={eDinarImage} />
        </div>
      </div>
    );
  };

  const cancellationConditions = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 lg:space-y-8 space-y-5 px-0 sm:p-6 xl:p-8'>
        <div className='lg:text-lg sm:text-sm text-sm mb-2  text-primary-blue font-bold '>
          Conditions d'annulation
        </div>

        <div className=''>
          <div className='text-sm text-red-600'>{data?.cancellation_policies?.message}</div>
        </div>
      </div>
    );
  };

  const commentBlock = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 lg:space-y-8 space-y-5 px-0 sm:p-6 xl:p-8'>
        <div className='lg:text-lg sm:text-sm text-sm mb-2  text-primary-blue font-bold '>
          Commentaire
        </div>
        <Textarea
          value={commentValue}
          onChange={e => setCommentValue(e.target.value)}
          placeholder="Si vous avez des remarques ou des demandes spécifiques prière de les mentionner ici - votre requête sera étudiée par l'établissement."
        />
      </div>
    );
  };

  const cols = [
    {
      id: "civility",
      title: "Civilité",
    },
    {
      id: "firstName",
      title: "Prénom",
    },
    {
      id: "lastName",
      title: "Nom de famille",
    },
    {
      id: "type",
      title: "",
    },
  ];

  const slug = useSearchParam("checkoutSlug") || "";

  const handleCreateBooking = async () => {
    if (!isAcceptedTermsAndConditions && !isCaptchaVerified) return setIsPaymentMethodError(true);
    setCreateBookingLoading(true);

    try {
      const reservation = {
        payment_type: "online",
        comment: commentValue,
        customer: {          email: generalInformationData.email!,
          name: generalInformationData.name!,
          phone: `+${
              generalInformationData.country || getCountryCallingCode("TN")
          }${generalInformationData.phone}`,
          reservation_details: customerData },
      };

      const res = await postCreateBokingService(slug, reservation);

      if (res.reservation_status === "new_pending") {
        setData(res);
      } else {
        window.location.href = res?.url;
      }
      setIsReservationDone(true);
    } catch (error) {
      setIsReservationFailed(true);
    }
    setCreateBookingLoading(false);
  };

  const { myHotelInformation } = useSearch();

  if (isReservationFailed) {
    return (
      <Page404
        image={BecomeAnAuthorImg}
        message='Désolé nous n’avons pas pu traiter votre requête, prière de faire une nouvelle recherche'
      />
    );
  }
  if (isError)
    return (
      <Page404 message='Oops la session en cours est terminée, prière de refaire une nouvelle recherche' />
    );
  if (loading) return <Preloader />;
  if (!data || data?.reservation_status === "canceled") return <Page404 />;
  if (!data || data?.reservation_status === "pending_waiting_for_payment")
    return <Page404 image={BecomeAnAuthorImg} message='En attente de paiement' />;

  return (
    <div className=''>
      <BasicHeader />
      {data?.reservation_status === "voucher" || data?.reservation_status === "pending" ? (
        <PayPage
          cover={data?.hotel.cover}
          name={data?.hotel.name}
          city={myHotelInformation?.city.city}
          hotelType={myHotelInformation?.hotel_type}
          address={data?.hotel.address}
          nbPersons={data?.pax?.nb_person}
          nbRooms={Object.keys(data?.details || {}).length}
          date={{
            endDate: moment(data?.end_date),
            startDate: moment(data?.start_date),
          }}
          bookingDate={data?.reservation_date}
          price={data?.price_details.total_price}
          paidAmount={data?.price_details.paid_amount}
          reservationNumber={data?.reservation_number}
          reservationStatus={data?.reservation_status}
          restAmountToPay={Number(data?.price_details?.amount_left_to_pay || 0)}
          cancellation_policies={data.cancellation_policies}
        />
      ) : (
        <>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleCreateBooking();
            }}>
            <div className={`nc-CheckOutPage ${className}`} data-nc-id='CheckOutPage'>
              <main className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'>
                <div className='w-full lg:w-3/5 xl:w-2/3 lg:pr-10'>
                  <div className='mb-5'>{generalInformation()}</div>

                  <div className='mb-5'>{renderGuestsInformation(cols)}</div>
                  {data?.cancellation_policies?.message && (
                    <div className='mb-5'>{cancellationConditions()}</div>
                  )}
                  <div className='mb-5'>{commentBlock()}</div>
                  <div className='mb-5'>{paymentMethod()}</div>

                  <div className='lg:flex justify-end hidden'>
                    <ButtonPrimary
                      loading={createBookingLoading}
                      disabled={disabledSubmitButton}
                      type='submit'
                      className={`!bg-green-700 ${!isCaptchaVerified ? "!opacity-60" : ""}`}>
                      {data.reservation_status === "new_pending"
                        ? "Demande de réservation"
                        : "Réserver"}
                    </ButtonPrimary>
                  </div>
                </div>
                <div className='hidden lg:block flex-grow w-min'>{renderSidebar()}</div>
                <div className='block lg:hidden'>
                  <MobileFooterSticky
                    price={data.price_details.total_price + " " + data.price_details.currency}
                    loading={createBookingLoading}
                    selectedDate={selectedDate}
                    disabledButton={disabledSubmitButton}
                  >
                    {renderSidebar()}
                  </MobileFooterSticky>
                </div>
              </main>
            </div>
          </form>
        </>
      )}
      <Footer hotelDetails={data?.hotel} />
    </div>
  );
};

export default CheckOutPage;
