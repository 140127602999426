import { IRoomType, ISearchResult } from "interfaces/searchResult";
import { TUserType } from "routers/types";

export const calculateRoomTotalPrice = (
  userType: TUserType,
  room: IRoomType,
  boardId: number,
) => {
  let price = 0;
  let price_without_promotion = 0;
  let firstNightPrice = 0;

    for (let index = 0; index < room.board.length; index++) {
      const arr = room.board[index];
      if (arr.data.id.toString() === boardId.toString()) {
    price = Number(arr.net);
    price_without_promotion = Number(arr.rate);
    firstNightPrice = 0; // TODO Number(arr.price_per_day[0])
  }
  }



  return {
    price,
    price_without_promotion,
    firstNightPrice,
  };
};

export const calculateStayTotalPrice = (
  userType: TUserType,
  rooms: ISearchResult["occupancies"],
  selectedRooms: { [roomIndex: number]: number },
  details?: {
    [roomIndex: number]: {
      [roomTypeId: number]: {
        boardId: number;
        non_mandatory_supplements?: number[];
        non_mandatory_supplement_rooms?: number;
        mandatory_supplement_rooms?: number[],
        mandatory_supplements?: number[]
      };
    };
  }
) => {
  let price = 0;
  let price_without_promotion = 0;
  let firstNightPrice = 0;
  let pricePerRoom: {
    [roomIndex: number]: {
      price: number;
      price_without_promotion: number;
    };
  } = {};
  if (rooms) {
    for (let roomIndex = 0; roomIndex < rooms.length; roomIndex++) {
      const room = rooms[roomIndex];
      const roomTypeId = selectedRooms?.[roomIndex];
      if (roomTypeId) {
        for (let roomTypeIndex = 0; roomTypeIndex < room.room_types.length; roomTypeIndex++) {
          const roomType = room.room_types[roomTypeIndex];
          if (roomTypeId === roomType.data.id) {
            const roomPrice = calculateRoomTotalPrice(
              userType,
              roomType,
                details?.[roomIndex][roomTypeId].boardId!,
            );
            pricePerRoom = {
              ...pricePerRoom,
              [roomIndex]: roomPrice,
            };
            price = price + Number(roomPrice.price);
            price_without_promotion =
              Number(price_without_promotion) + Number(roomPrice.price_without_promotion);

            firstNightPrice = firstNightPrice + Number(roomPrice.firstNightPrice);
          }
        }
      }
    }
  }

  return {
    price,
    price_without_promotion,
    firstNightPrice,
    pricePerRoom,
  };
};

export const getHotelHighestPromotion = (promotions?: ISearchResult["promotions"]) => {
  if (!promotions) return;
  let highestPromotion = promotions[0];
  promotions.forEach((prom, index) => {
    if (index === 0) return;
    if (prom.percent > highestPromotion.percent) {
      highestPromotion = prom;
    }
  });

  return highestPromotion;
};
