import { FC, useCallback, useEffect, useState } from "react";
import StayCardH from "components/StayCardH/StayCardH";
import Checkbox from "shared/Checkbox/Checkbox";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import { ISearchResult } from "interfaces/searchResult";
import Slider from "rc-slider";
import { useSearch } from "context/SearchContext";

export interface SectionGridHasMapProps {
  hotels: ISearchResult[];
}

export interface ISelectedFiltersHotelList {
  rangePrices: [number, number];
  amenities: string[];
  arrangement: string[];
  freeCancellation: boolean;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({ hotels = [] }) => {
  const [selectedFilters, setSelectedFilters] = useState<ISelectedFiltersHotelList>({
    rangePrices: [0, 0],
    amenities: [],
    arrangement: [],
    freeCancellation: false,
  });
  const [data, setData] = useState<ISearchResult[]>(hotels);

  useEffect(() => {
    setData(hotels);
    setSelectedFilters(v => ({
      ...v,
      rangePrices: [Math.min(...getFilters(hotels).prices), Math.max(...getFilters(hotels).prices)],
    }));
  }, [hotels]);

  const { userType, searchData } = useSearch();

  useEffect(() => {
    setData(
      hotels.filter(hotel => {
        const hasSelectedRangePrice = hotel?.occupancies?.find(room =>
          room.room_types.find(type => {
            return type.board.find(arr => {
              const price = userType === "Hotel" ? Number(arr.net) : Number(arr.net);

              return (
                price >= selectedFilters.rangePrices[0] && price <= selectedFilters.rangePrices[1]
              );
            });
          })
        );
        const hotelArragenemts = hotel?.occupancies?.reduce((acc, val) => {
          acc = {
            ...acc,
            ...val.room_types.reduce((acc, val) => {
              acc = {
                ...acc,
                ...val.board.reduce((acc, val) => {
                  acc = {
                    ...acc,
                    [val.data.id]: val.data.name,
                  };
                  return acc;
                }, {}),
              };
              return acc;
            }, {}),
          };
          return acc;
        }, {});

        const hasSelectedArrangements = selectedFilters.arrangement.length
          ? !selectedFilters.arrangement.some(arr => !Object.keys(hotelArragenemts).includes(arr))
          : true;
        const hotelAmenagements = Object.keys(hotel.data.amenities);

        const hasSelectedAmenagements = selectedFilters.amenities.length
          ? !selectedFilters.amenities.some(arr => !hotelAmenagements.includes(arr))
          : true;

        if (selectedFilters.freeCancellation) {
          return (
            hotel.cancellation_deadline?.is_free_cancellation &&
            hasSelectedRangePrice &&
            hasSelectedArrangements &&
            hasSelectedAmenagements
          );
        }
        return hasSelectedRangePrice && hasSelectedArrangements && hasSelectedAmenagements;
      })
    );
  }, [selectedFilters]);

  const getFilters = useCallback(
    (array?: ISearchResult[]) => {
      return (array || hotels).reduce(
        (acc, val) => {
          acc.amenities = {
            ...acc.amenities,
            ...val.data.amenities,
          };
          const hotelRoomsArrangements = val.occupancies.reduce((acc, val) => {
            acc = {
              ...acc,
              ...val.room_types.reduce((acc, val) => {
                acc = {
                  ...acc,
                  ...val.board.reduce((acc, val) => {
                    acc = {
                      ...acc,
                      [val.data.id]: val.data.name,
                    };
                    return acc;
                  }, {}),
                };
                return acc;
              }, {}),
            };
            return acc;
          }, {});

          acc.arrangement = {
            ...acc.arrangement,
            ...hotelRoomsArrangements,
          };
          const hotelRoomPrices = val.occupancies.reduce((acc, val) => {
            acc = {
              ...acc,
              ...val.room_types.reduce((acc, val) => {
                acc = {
                  ...acc,
                  ...val.board.reduce((acc, val) => {
                    acc = {
                      ...acc,
                      [userType === "Hotel" ? Number(val.net) : Number(val.net)]: val.data.name,
                    };
                    return acc;
                  }, {}),
                };
                return acc;
              }, {}),
            };
            return acc;
          }, {});

          acc.prices = [
            Math.min(...Object.keys(hotelRoomPrices).map(el => Number(el))),
            Math.max(...Object.keys(hotelRoomPrices).map(el => Number(el))),
          ];
          return acc;
        },
        {
          amenities: {},
          arrangement: {},
          prices: [0, 0],
        }
      );
    },
    [hotels]
  );

  const onChangeMoreFilterItem =
    (filterKey: "amenities" | "arrangement", key: string) => (checked: boolean) => {
      if (!checked) {
        setSelectedFilters({
          ...selectedFilters,
          [filterKey]: selectedFilters[filterKey].filter(el => el !== key),
        });
      } else {
        setSelectedFilters({
          ...selectedFilters,
          [filterKey]: selectedFilters[filterKey].concat(key),
        });
      }
    };

  const renderMoreFilterItem = (data: any, filterKey: "amenities" | "arrangement") => {
    return (
      <div className='  flex flex-col'>
        <div className='flex flex-col space-y-3'>
          {Object.entries(data).map(([key, label]) => (
            <Checkbox
              key={key}
              name={key}
              label={label as { name: string }}
              onChange={onChangeMoreFilterItem(filterKey, key)}
              checked={selectedFilters[filterKey].includes(key)}
              labelClassName='text-sm'
              inputClassName='w-4 h-4'
            />
          ))}
        </div>
      </div>
    );
  };

  const onChangePriceFilterInput = (min?: number, max?: number) => {
    if (min && max) {
      setSelectedFilters({ ...selectedFilters, rangePrices: min > max ? [max, min] : [min, max] });
    } else if (min) {
      setSelectedFilters({
        ...selectedFilters,
        rangePrices: [min || 0, selectedFilters.rangePrices[1]],
      });
    } else if (max) {
      setSelectedFilters({
        ...selectedFilters,
        rangePrices: [selectedFilters.rangePrices[0], max],
      });
    }
  };

  return (
    <div>
      <div className='relative flex min-h-screen justify-center'>
        {/* CARDSSSS */}
        <div className='min-h-screen w-full max-w-7xl flex-shrink-0 xl:px-8 '>
          <Heading2 hotelLength={data.length} />
          <div className=''>
            <TabFilters
              filters={getFilters()}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
            />
          </div>
          <div className='flex justify-center flex-col lg:flex-row'>
            {/* Side filters */}
            <div className='mr-6 border rounded-3xl border-neutral-200 hidden lg:block w-96'>
              {/* FORM */}
              <div className='flex-grow overflow-y-auto'>
                <div className='px-10 divide-y divide-neutral-200 dark:divide-primary-gray'>
                  <div className='py-7'>
                    <h3 className='text-base font-medium'>Services & équipements</h3>
                    <div className='mt-3 relative '>
                      {renderMoreFilterItem(getFilters().amenities, "amenities")}
                    </div>
                  </div>
                  <div className='py-7'>
                    <h3 className='text-base font-medium'>Arrangement</h3>
                    <div className='mt-3 relative '>
                      {renderMoreFilterItem(getFilters().arrangement, "arrangement")}
                    </div>
                  </div>
                  <div className='py-7'>
                    <h3 className='text-base font-medium'>Prix</h3>
                    <div className='mt-3 relative '>
                      <Slider
                        range
                        className='text-red-400'
                        trackStyle={{ background: "#033357" }}
                        min={getFilters().prices[0]}
                        max={getFilters().prices[1]}
                        value={[
                          selectedFilters.rangePrices[0] || getFilters().prices[0],
                          selectedFilters.rangePrices[1] || getFilters().prices[1],
                        ]}
                        allowCross={false}
                        onChange={e =>
                          setSelectedFilters({
                            ...selectedFilters,
                            rangePrices: e as [number, number],
                          })
                        }
                      />
                      <div className='my-4'>
                        <label
                          htmlFor='maxPrice'
                          className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'>
                          Minimum
                        </label>
                        <div className='mt-1 relative rounded-md w-32'>
                          <div className='absolute inset-y-0 right-4 pl-3 flex items-center pointer-events-none'>
                            <span className='text-neutral-500 sm:text-sm'>{searchData?.currency}</span>
                          </div>
                          <input
                            type='number'
                            onChange={e => onChangePriceFilterInput(Number(e.target.value))}
                            name='maxPrice'
                            id='maxPrice'
                            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-11 text-center sm:text-sm border-neutral-200 rounded-full text-neutral-black '
                            onBlur={e =>
                              onChangePriceFilterInput(
                                selectedFilters.rangePrices[0],
                                selectedFilters.rangePrices[1]
                              )
                            }
                            value={selectedFilters.rangePrices[0] || getFilters().prices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor='maxPrice'
                          className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'>
                          Maximum
                        </label>
                        <div className='mt-1 relative rounded-md w-32'>
                          <div className='absolute inset-y-0 right-4 pl-3 flex items-center pointer-events-none'>
                            <span className='text-neutral-500 sm:text-sm'>{searchData?.currency}</span>
                          </div>
                          <input
                            type='number'
                            name='maxPrice'
                            id='maxPrice'
                            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-11 text-center  sm:text-sm border-neutral-200 rounded-full text-neutral-black '
                            onChange={e =>
                              onChangePriceFilterInput(undefined, Number(e.target.value))
                            }
                            onBlur={e =>
                              onChangePriceFilterInput(
                                selectedFilters.rangePrices[0],
                                selectedFilters.rangePrices[1]
                              )
                            }
                            value={selectedFilters.rangePrices[1] || getFilters().prices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='py-2 mb-4'>
                    <h3 className='text-base font-medium'>Type d'annulation</h3>
                    <div className='mt-3 relative '>
                      <Checkbox
                        name={"Annulation gratuite"}
                        label={"Annulation gratuite"}
                        checked={selectedFilters.freeCancellation}
                        onChange={checked =>
                          setSelectedFilters({ ...selectedFilters, freeCancellation: checked })
                        }
                        labelClassName='text-sm'
                        inputClassName='w-4 h-4'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='w-full'>
              {data.map(item => (
                <div key={item.data.slug} className='mb-10'>
                  <StayCardH hotel={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
