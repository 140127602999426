import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { XIcon } from "@heroicons/react/solid";
import { DateRage } from "components/HeroSearchForm2/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";

const MobileFooterSticky: React.FC<{
  children: JSX.Element;
  price: string;
  selectedDate: DateRage;
  loading: boolean;
  disabledButton: boolean
}> = ({ children, price, selectedDate, loading, disabledButton }) => {
  const [showModal, setShowModal] = useState(false);

  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-primary-gray border-t border-neutral-200 dark:border-neutral-6000 z-20">
      <div className="container flex items-center justify-between">
        <div className="w-full">
          <span className="block text-xl font-semibold uppercase">{price}</span>
          <span
            onClick={openModal}
            className="block text-sm underline font-medium"
          >
            {converSelectedDateToString(selectedDate)}
          </span>
        </div>
        <Transition appear show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="HeroSearchFormMobile__Dialog relative z-50"
            onClose={closeModal}
          >
            <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
              <div className="flex h-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out transition-transform"
                  enterFrom="opacity-0 translate-y-52"
                  enterTo="opacity-100 translate-y-0"
                  leave="ease-in transition-transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-52"
                >
                  <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                    <>
                      <div className="absolute left-0 top-0 p-4 bg-white w-full z-50 ">
                        <button
                          className="focus:outline-none focus:ring-0"
                          onClick={closeModal}
                        >
                          <XIcon className="w-5 h-5 text-black dark:text-white" />
                        </button>
                      </div>
                      <div className="overflow-scroll">{children}</div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <ButtonPrimary
          type="submit"
          loading={loading}
          className={`${disabledButton ? "opacity-60" : ""}`}
          sizeClass="px-5 sm:px-7 py-3 !rounded-2xl"
          disabled={disabledButton}
        >
          Réserver
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default MobileFooterSticky;
