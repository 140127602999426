import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

export const useFetch = <T,>(url: string): [T | undefined, boolean] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState(true);

  const fetch = async () => {
    setLoading(true);
    const key = localStorage.getItem("key");
    try {
      const res = await axios.get<any, AxiosResponse<T>>(
        `${process.env.REACT_APP_API_URL}api/${process.env.REACT_APP_API_VERSION}/${url}/?key=${key}`
      );
      setData(res.data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetch();
  }, []);

  return [data, loading];
};
