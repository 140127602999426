import React, { FC } from "react";
import { PathName } from "routers/types";
import HeroSearchForm2Mobile from "./HeroSearchForm2Mobile";


const HeroSearchForm2MobileFactory: FC<{destinationName?: string}> = ({destinationName}) => {
  // const location = useLocation();
  // if (PAGES_REAL_ESTATE.includes(location.pathname as PathName)) {
  //   return <HeroSearchForm2RealEstateMobile />;
  // }
  return <HeroSearchForm2Mobile  destinationName={destinationName} />;
};

export default HeroSearchForm2MobileFactory;
