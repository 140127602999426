export const STOP_SALE_TEXT = 'Stop Sale';
export const MINIMUM_NIGHTS_TEXT = (minStay: number) => `Minimum ${minStay} nuitées`;
export const ON_REQUEST_TEXT = 'Sur demande';
export const AVAILABLE_TEXT = 'Disponible';

export const STOP_SALE_COLOR = '#e00011';
export const ON_REQUEST_COLOR = '#ff8400';
export const AVAILABLE_COLOR = '#15803D';
export const UNAVAILABLE_COLOR = '#ccc';

//00AA6C
