import React, { useState } from "react";
import { HotelSetting } from "interfaces/hotel";
import Header3 from "./Header3";

export const DefaultHeader: React.FC<{
  logo: HotelSetting["logo"];
  destinationName: string;
}> = ({ logo, destinationName }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Desktop Header */}
      <div
        className={`container mx-auto my-4 flex ${
          logo ? "justify-between" : "justify-center"
        } w-full items-center`}
      >
        {logo && <img className="h-12 hidden lg:block" src={logo} alt="" />}
        <div className="hidden lg:flex sticky top-0 z-20 space-y-40 bg-white">
          <div className="container mx-auto py-3 flex justify-center items-center text-black">
            <nav className="flex space-x-16">
              <a
                href="#rooms_available"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#rooms_available")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Chambres
              </a>
              <a
                href="#services_equipment"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#services_equipment")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Services et équipements
              </a>
              <a
                href="#accommodation"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#accommodation")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Hébergement
              </a>
              <a
                href="#general_infos"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#general_infos")
                    ?.scrollIntoView({ behavior: "smooth" });
                }}
              >
                Informations générales
              </a>
            </nav>
          </div>
        </div>
        <div></div>
      </div>

      {/* Only show on large screens */}
      <div className="hidden lg:flex flex-grow my-5 justify-center">
        <Header3 destinationName={destinationName} isOnlySearch />
      </div>

      {/* Mobile Header */}
      <div className="flex justify-between items-center lg:hidden my-5 px-4">
        {logo && <img className="h-12" src={logo} alt="" />}
        <div className="flex flex-grow justify-center">
          <Header3 destinationName={destinationName} isOnlySearch />
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={toggleMenu}
            className="text-neutral-black text-xl focus:outline-none"
          >
            &#9776; {/* Hamburger icon */}
          </button>
        </div>
      </div>

      {/* Mobile Sidebar Menu */}
      {isMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-30">
          <div className="fixed top-0 left-0 h-full w-64 bg-white shadow-lg z-40">
            <div className="p-4 flex justify-between items-center border-b">
              <span className="font-bold text-lg">Menu</span>
              <button
                onClick={toggleMenu}
                className="text-neutral-black text-xl focus:outline-none"
              >
                &times; {/* Close icon */}
              </button>
            </div>
            <nav className="p-4 flex flex-col space-y-4">
              <a
                href="#rooms_available"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#rooms_available")
                    ?.scrollIntoView({ behavior: "smooth" });
                  toggleMenu();
                }}
              >
                Chambres
              </a>
              <a
                href="#services_equipment"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#services_equipment")
                    ?.scrollIntoView({ behavior: "smooth" });
                  toggleMenu();
                }}
              >
                Services et équipements
              </a>
              <a
                href="#accommodation"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#accommodation")
                    ?.scrollIntoView({ behavior: "smooth" });
                  toggleMenu();
                }}
              >
                Hébergement
              </a>
              <a
                href="#general_infos"
                className="text-neutral-black font-medium hover:text-primary-600"
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector("#general_infos")
                    ?.scrollIntoView({ behavior: "smooth" });
                  toggleMenu();
                }}
              >
                Informations générales
              </a>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};
