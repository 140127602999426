import { useSearch } from "context/SearchContext";
import { Link } from "react-router-dom";

export default function BasicHeader() {
  const { myHotelInformation } = useSearch();

  return (
    <div className='container mx-auto my-4'>
      {myHotelInformation?.logo && (
        <Link to={"/hotel-detail/" + myHotelInformation?.slug}>
          <img className='h-12' src={myHotelInformation?.logo} alt='' />
        </Link>
      )}
    </div>
  );
}
