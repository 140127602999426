import { useState } from "react";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import { FocusedInputShape } from "react-dates";
import StayDatesRangeInput from "./StayDatesRangeInput";
import moment from "moment";
import { FC } from "react";
import { useSearch } from "context/SearchContext";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
}

const StaySearchForm: FC<StaySearchFormProps> = () => {
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(null);
  const { myHotelInformation, setSearchData, searchData, getSearchPathWithQuery, searchDestinations, userType } = useSearch();

  const getSearchLink = () => {
    if (myHotelInformation?.slug) {
      return getSearchPathWithQuery("/hotel-detail/" + myHotelInformation.slug);
    } else if (searchDestinations.hotels.find(el => el.slug == searchData.hotel_slug)) {
      return getSearchPathWithQuery("/hotel-detail/" + searchData.hotel_slug);
    } else {
      return getSearchPathWithQuery("/hotels");
    }
  };

  const renderForm = () => {
    return (
      <form className='w-full relative flex rounded-full shadow-xl  bg-white '>
        {userType === "Agency" && (
          <LocationInput
            defaultValue={searchData.location || searchData.hotel_slug}
            onChange={e =>
              setSearchData({
                ...searchData,
                location: e,
              })
            }
            onInputDone={(location, hotel_slug) => {
              setSearchData({ ...searchData, location, hotel_slug });
              setDateFocused("startDate");
            }}
            className='flex-[1.5]'
          />
        )}
        <StayDatesRangeInput
          defaultValue={searchData.dateRange}
          onChange={e => {
            setSearchData({
              ...searchData,
              dateRange: e,
            });
          }}
          className='flex-[2]'
          defaultFocus={dateFocused}
        />
        <GuestsInput
          rooms={searchData.rooms}
          defaultValue={{}}
          onChange={e =>
            setSearchData({
              ...searchData,
              rooms: e,
            })
          }
          className='flex-[1.5]'
          buttonSubmitHref={getSearchLink()}
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
