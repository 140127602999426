import SectionHero from "components/SectionHero/SectionHero";


function PageHome() {
  return (
    <div className="nc-PageHome relative ">

      <div className="container relative space-y-24 lg:space-y-28 ">
        <SectionHero className="" />
      </div>
    </div>
  );
}

export default PageHome;
