import { FC } from "react";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import StaySearchForm from "components/HeroSearchForm2Mobile/StaySearchForm";
import { useSearch } from "context/SearchContext";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { myHotelInformation, getSearchPathWithQuery, searchData, searchDestinations } = useSearch();

  const getSearchLink = () => {
    if (myHotelInformation?.slug) {
      return getSearchPathWithQuery("/hotel-detail/" + myHotelInformation.slug, true);
    } else if (searchDestinations.hotels.find(el => el.slug == searchData.hotel_slug)) {
      return getSearchPathWithQuery("/hotel-detail/" + searchData.hotel_slug, true);
    } else {
      return getSearchPathWithQuery("/hotels");
    }
  };

  return (
    <div className={`nc-SectionHero  flex flex-col-reverse lg:flex-col relative  items-center ${className}`} data-nc-id='SectionHero'>
      <div className='hidden lg:flex min-h-screen  z-10 mb-12 lg:mb-0 w-full bg-contain items-center'>
        <HeroSearchForm />
      </div>
      <div className='lg:hidden w-full max-w-lg mx-auto z-10'>
        <div className='HeroSearchForm2Mobile'>
          <div className='HeroSearchFormMobile__Dialog relative z-max'>
            <div>
              <div className='flex h-full'>
                <div className='relative h-full overflow-hidden flex-1 flex flex-col justify-between '>
                  <div className='flex-1 pt-3 px-1 flex overflow-hidden'>
                    <div className='flex-1 overflow-y-auto py-4 transition-opacity animate-[myblur_0.4s_ease-in-out]'>
                      <StaySearchForm />
                    </div>
                  </div>
                  <div className='px-4 py-3 dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 text-sm'>
                    <ButtonSubmit href={getSearchLink()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero;
