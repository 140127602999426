import { FC, useRef } from "react";
import { Dialog } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import GallerySlider from "components/GallerySlider/GallerySlider";

export interface ModalPhotosProps {
  imgs: string[];
  onClose: () => void;
  isOpen: boolean;
  initFocus?: number;
  uniqueClassName: string;
}

const ModalPhotos: FC<ModalPhotosProps> = ({ imgs, isOpen, onClose }) => {
  let completeButtonRef = useRef(null);

  const renderModalPhotos = () => {
    return (
      <Dialog
        initialFocus={completeButtonRef}
        as='div'
        className='fixed inset-0 z-max overflow-y-auto dark bg-primary-gray text-primary-gray hiddenScrollbar'
        onClose={onClose}
        open={isOpen}>
        <div className='min-h-screen px-4 text-center'>
          <Dialog.Overlay className='fixed inset-0 bg-white dark:bg-primary-gray' />
          <div ref={completeButtonRef} className='absolute left-2 top-2 md:top-4 md:left-4 z-max'>
            <ButtonClose className=' sm:w-11 sm:h-11' onClick={onClose} />
          </div>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>

          <div className='relative inline-block w-full max-w-5xl py-8 h-screen align-middle mx-auto'>
            <GallerySlider galleryImgs={imgs} uniqueID="some" />
          </div>
        </div>
      </Dialog>
    );
  };

  return renderModalPhotos();
};

export default ModalPhotos;
