import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { ISearchResult } from "interfaces/searchResult";
import React, { FC, Fragment, useEffect, useState } from "react";
import { FocusedInputShape } from "react-dates";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ISelectedArrangements } from "./StayCardPricesTable/StayCardPricesTable";
import { DisplaySideBarBody } from "containers/ListingDetailPage/DisplaySideBarBody";
import { useSearch } from "context/SearchContext";

interface ModalBookingDetailsProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
  hotel?: ISearchResult;
  selectedArrangements?: ISelectedArrangements;
  selectedRooms?: {
    [roomIndex: number]: number /* number = roomTypeId */;
  };
  totalPrice?: number;
  totalPriceWithoutPromotion?: number;
  handleConfirmButton: () => void;
  loading: boolean;
}

const ModalBookingDetails: FC<ModalBookingDetailsProps> = ({
  renderChildren,
  hotel,
  selectedArrangements,
  selectedRooms,
  totalPrice,
  totalPriceWithoutPromotion,
  handleConfirmButton,
  loading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { searchData } = useSearch()

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  return (
    <>
      {renderChildren && renderChildren({ openModal })}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as='div'
          className='HeroSearchFormMobile__Dialog relative z-50'
          onClose={closeModal}>
          <div className='fixed inset-0 bg-neutral-100 dark:bg-neutral-900'>
            <div className='flex h-full'>
              <Transition.Child
                as={Fragment}
                enter='ease-out transition-transform'
                enterFrom='opacity-0 translate-y-52'
                enterTo='opacity-100 translate-y-0'
                leave='ease-in transition-transform'
                leaveFrom='opacity-100 translate-y-0'
                leaveTo='opacity-0 translate-y-52'>
                <Dialog.Panel className='relative h-full overflow-hidden flex-1 flex flex-col justify-between '>
                  <>
                    <div className='absolute left-4 top-4'>
                      <button className='focus:outline-none focus:ring-0' onClick={closeModal}>
                        <XIcon className='w-5 h-5 text-black dark:text-white' />
                      </button>
                    </div>

                    <div className='flex-1 pt-12 p-1 flex flex-col overflow-hidden'>
                      <div className='flex-1 flex flex-col overflow-hidden bg-white dark:bg-primary-gray'>
                        <div className='flex-1 flex flex-col transition-opacity animate-[myblur_0.4s_ease-in-out] overflow-auto'>
                          <div className='p-5 '>
                            <span className='block font-semibold text-xl sm:text-2xl'>
                              Votre sélection
                            </span>
                          </div>
                          <div className={`flex-1 relative flex z-10 overflow-hidden`}>
                            <div className='listingSectionSidebar__wrap border-none'>
                              <DisplaySideBarBody
                                hotel={hotel!}
                                selectedArrangements={selectedArrangements!}
                                selectedRooms={selectedRooms!}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=' px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between'>
                      <div className='flex items-start flex-col'>
                        {totalPriceWithoutPromotion !== totalPrice && (
                          <div className='text-red-600 text-sm line-through'>
                            {totalPriceWithoutPromotion} {searchData?.currency}
                          </div>
                        )}
                        <div className='underline font-bold text-xl'>{totalPrice} {searchData?.currency}</div>
                      </div>
                      <ButtonPrimary
                        loading={loading}
                        sizeClass='px-6 py-3 !rounded-xl !bg-green-700'
                        onClick={() => {
                          closeModal();
                          handleConfirmButton();
                        }}>
                        Réserver
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalBookingDetails;
