import { useEffect, useState, FC } from "react";
import { FocusedInputShape } from "react-dates";
import { useSearch } from "context/SearchContext";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import moment from "moment";
import NcDropDown from "shared/NcDropDown/NcDropDown";

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export type StaySearchFormFields = "currency" | "guests" | "dates" | 'location';

export interface StaySearchFormProps {
  haveDefaultValue?: boolean;
  defaultFieldFocus?: StaySearchFormFields;
}

const StaySearchForm: FC<StaySearchFormProps> = ({ haveDefaultValue = false, defaultFieldFocus }) => {
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(null);
  const { searchDestinations, searchData, getSearchPathWithQuery, myHotelInformation, setSearchData, userType, handleSearch } = useSearch();
  const [currentField, setCurrentField] = useState("" as StaySearchFormFields)


  useEffect(() => {
    if (defaultFieldFocus === "dates") {
      setDateFocused("startDate");
    } else {
      setDateFocused(null);
    }
  }, [defaultFieldFocus]);

  const getSearchLink = () => {
    if (searchDestinations.hotels.find(el => el.slug === searchData.hotel_slug)) {
      return getSearchPathWithQuery("/hotel-detail/" + searchData.hotel_slug, true);
    } else if (myHotelInformation?.slug) {
      return getSearchPathWithQuery("/hotel-detail/" + myHotelInformation?.slug, true);
    } else {
      return getSearchPathWithQuery("/hotels");
    }
  };

  const renderTrigger = () => {
    return <div className="text-start px-8">
      <span className="block font-semibold">
        Devise
      </span>
      <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
        {searchData.currency}
      </span>
    </div>
  }

  const renderItem = (item: { id: string, name: string, icon: string },) => {
    return <div
      className="py-2 px-4 hover:bg-gray-100 rounded-md"
      onClick={() => setSearchData((prev: any) => ({ ...prev, currency: item.name }))}
    >{item.name}</div>
  }

  const renderForm = () => {
    return (
      <form className='relative flex items-center rounded-full border border-neutral-200 dark:border-neutral-700'>
        {userType === "Agency" && (
          <LocationInput
            defaultValue={searchData.hotel_slug! || searchData.location!}
            onInputDone={(location, hotel_slug) => {
              setSearchData({ ...searchData, location, hotel_slug });
              setDateFocused("startDate");
            }}
            className='flex-[1.5]'
            autoFocus={defaultFieldFocus === "location"}
          />
        )}

        <StayDatesRangeInput
          onClick={() => setCurrentField("dates")}
          defaultValue={searchData.dateRange}
          onChange={e => {
            setSearchData({ ...searchData, dateRange: e });
          }}
          defaultFocus={dateFocused}
          className="flex-1"
        />
        <NcDropDown
          data={
            [
              { id: "TND", name: "TND", icon: "" },
              { id: "EUR", name: "EUR", icon: "" },
              { id: "USD", name: "USD", icon: "" }
            ]
          }
          onClick={() => setCurrentField("location")}
          renderTrigger={renderTrigger}
          renderItem={renderItem}
          className={`flex-1 ${currentField === 'location' ? "nc-hero-field-focused--2" : ""} py-3`}
        />
        <GuestsInput
          onClick={() => setCurrentField("guests")}
          rooms={searchData.rooms}
          defaultValue={{}}
          onChange={e => {
            setSearchData({ ...searchData, rooms: e })
          }}
          className="flex-1"
          buttonSubmitHref={getSearchLink()}
          onClickButton={() => {
            handleSearch().then(() => {
              document.getElementById("search-room-result")?.scrollIntoView({ behavior: "smooth" })
              window.history.replaceState(null, "Maison Kinko", getSearchLink());
            })
          }}
        />
      </form>
    );
  };

  return <>{renderForm()}</>;
};

export default StaySearchForm;
