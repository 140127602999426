import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface BadgeProps {
  className?: string;
  label?: ReactNode;
  type?: "success" | "error" | "warning" | "info"; // types of badge styles
  size?: "small" | "medium" | "large"; // size options
  href?: string; // Optional link
}

const Badge: FC<BadgeProps> = ({
  className = "relative",
  label = "Badge",
  type = "info",
  size = "medium",
  href,
}) => {
  const getTypeStyles = () => {
    switch (type) {
      case "success":
        return "bg-green-50 text-green-700 ring-green-600/20";
      case "error":
        return "bg-red-50 text-red-700 ring-red-600/10";
      case "warning":
        return "bg-yellow-50 text-yellow-800 ring-yellow-600/20";
      case "info":
      default:
        return "bg-gray-50 text-gray-600 ring-gray-500/10";
    }
  };

  // Map sizes to corresponding styles
  const getSizeStyles = () => {
    switch (size) {
      case "small":
        return "px-1 py-0.2 text-[10px]";
      case "large":
        return "px-3 py-1 text-sm";
      case "medium":
        return "px-2 py-1 text-xs";
      default:
        return "px-2 py-1 text-xs";
    }
  };

  const CLASSES = `inline-flex items-center rounded-md font-medium ring-1 ring-inset ${getTypeStyles()} ${getSizeStyles()} ${className}`;

  return !!href ? (
    <Link to={href} className={CLASSES}>
      {label}
    </Link>
  ) : (
    <span className={CLASSES}>{label}</span>
  );
};

export default Badge;
