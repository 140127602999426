import { IRoom } from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { useSearch } from "context/SearchContext";
import React, { useContext, useState } from "react";
import { addLetterSofPlural } from "utils/string";
import GuestsInput, { GuestsObject } from "./GuestsInput";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";

const StaySearchForm = () => {
  const [fieldNameShow, setFieldNameShow] = useState<"location" | "dates" | "guests">("location");
  const { searchData, setSearchData, userType } = useSearch();
  const { location, dateRange, rooms } = searchData;

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-primary-gray ${
          isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}>
        {userType === "Agency" && (
          <LocationInput
            isActive={isActive}
            defaultValue={location}
            setFieldNameShow={setFieldNameShow}
            onChange={(location, hotel_slug) => {
              setSearchData({ ...searchData, location, hotel_slug });
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";
    const startDateString = dateRange.startDate?.format("MMM DD");
    const endDateString =
      dateRange.endDate?.get("month") !== dateRange.startDate?.get("month") ? dateRange.endDate?.format("MMM DD") : dateRange.endDate?.format("DD");
    const dateSelected = startDateString && endDateString ? `${startDateString} - ${endDateString}` : `${startDateString || endDateString || ""}`;
    return (
      <div
        className={`w-full bg-white dark:bg-primary-gray overflow-hidden ${
          isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}>
        {!isActive ? (
          <button className={`w-full flex justify-between text-sm font-medium p-4  `} onClick={() => setFieldNameShow("dates")}>
            <span className='text-neutral-400'>Séjour</span>
            <span>{dateSelected || "Add date"}</span>
          </button>
        ) : (
          <StayDatesRangeInput
            defaultValue={dateRange}
            onChange={data => {
              setSearchData({ ...searchData, dateRange: data });
              if (!dateRange.endDate && data.endDate) setFieldNameShow("guests");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";

    return (
      <div
        className={`w-full bg-white dark:bg-primary-gray overflow-hidden ${
          isActive ? "rounded-2xl shadow-lg" : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}>
        {!isActive ? (
          <button className={`w-full flex justify-between text-sm font-medium p-4`} onClick={() => setFieldNameShow("guests")}>
            <span className='text-neutral-400'>Chambre</span>
            <span>{`${rooms.length} Chambre${addLetterSofPlural(rooms.length)} `}</span>
          </button>
        ) : (
          <GuestsInput
            rooms={rooms}
            onChange={(data: IRoom[]) => {
              setSearchData({ ...searchData, rooms: data });
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className='w-full space-y-5'>
        {/*  */}
        {renderInputLocation()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {renderInputGuests()}
      </div>
    </div>
  );
};

export default StaySearchForm;
