import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";

export interface BadgeProps {
  className?: string;
  name: ReactNode;
  color?: string;
  href?: string;
}

const Badge: FC<BadgeProps> = ({
  className = "relative",
  name,
  color = "blue",
  href,
}) => {
 
  const style = {
    backgroundColor: color, // Background color for the badge
    color: "#fff", // Text color (white for better contrast)
    fontSize: "9px",
  };

  const CLASSES =
    "nc-Badge inline-flex px-2 rounded-full font-medium text-center ml-3 " +
    className;
  return !!href ? (
    <Link
      to={href || ""}
      className={`transition-colors hover:text-white duration-300 ${CLASSES}`}
    >
      {name}
    </Link>
  ) : (
    <span className={`${CLASSES}`} style={style}>
      {name}
    </span>
  );
};

export default Badge;
