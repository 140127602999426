import IconAndText from "components/IconAndText";
import { FC } from "react";

const EquipmentList: FC<{ equipments: Array<{ la_icon: string; title: string }> }> = ({ equipments }) => {
  return (
    <div className="flex flex-wrap gap-3">
      {equipments?.slice(0, 6).map((el) => (
        <IconAndText icon={el.la_icon} name={el.title} key={el.title} />
      ))}
    </div>
  );
};

export default EquipmentList;
