import React, { useEffect, useState } from "react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import { DEFAULT_ROOM_DATA, IRoom } from "components/HeroSearchForm/GuestsInput";
import Select from "shared/Select/Select";
import { MinusIcon } from "@heroicons/react/solid";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { addLetterSofPlural } from "utils/string";

export interface GuestsObject {
  guestAdults?: number;
  guestChildren?: number;
  guestInfants?: number;
  guestChildrenAge?: number;
}
export interface GuestsInputProps {
  rooms?: IRoom[];
  defaultValue?: GuestsObject;
  onChange?: (data?: IRoom[] | any) => void;
  className?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({ defaultValue, rooms, onChange, className = "" }) => {
  const handleChangeData = (
    value: number,
    type: keyof GuestsObject,
    roomIndex: number,
    childIndex?: number
  ) => {
    const data = rooms?.map((room, index) => {
      if (roomIndex === index) {
        if (type === "guestAdults") return { ...room, guestAdults: value };
        if (type === "guestChildrenAge")
          return {
            ...room,
            guestChildren: room.guestChildren.map((_, i) => (i === childIndex ? value : _)),
          };
        return {
          ...room,
          guestChildren:
            value > room.guestChildren.length
              ? room.guestChildren.concat(0)
              : room.guestChildren.filter((_, i) => i + 1 !== room.guestChildren.length),
        };
      }
      return room;
    });
    onChange && onChange(data);
  };

  return (
    <div className={`flex flex-col relative p-5 ${className}`}>
      {/* <span className="mb-5 block font-semibold text-xl sm:text-2xl">
        Qui va venir ?
      </span> */}
      {rooms?.map((room, index) => (
        <div key={index} className='border-b py-3'>
          <div className='flex items-center mb-2  pb-2'>
            <div className='mr-3 text-sm'>Chambre {index + 1} </div>
            {rooms.length !== 1 && (
              <button
                type='button'
                onClick={() => onChange && onChange(rooms?.filter((_, i) => i !== index))}>
                <MinusCircleIcon className='w-7 h-7' />
              </button>
            )}
          </div>

          <NcInputNumber
            className='w-full'
            defaultValue={room.guestAdults}
            onChange={value => handleChangeData(value, "guestAdults", index)}
            max={5}
            label='Adultes'
            desc=''
          />
          <NcInputNumber
            className='w-full mt-6'
            defaultValue={room.guestChildren.length}
            onChange={value => handleChangeData(value, "guestChildren", index)}
            max={4}
            label='Enfants'
            desc='Âges 0–17'
          />

          {room.guestChildren.map((child, i) => (
            <div className='mt-3' key={i}>
              <div className=''>Age Enfant {i + 1}</div>

              <Select
                onChange={e =>
                  handleChangeData(Number(e.target.value), "guestChildrenAge", index, i)
                }
                value={child}>
                {Array.from({ length: 18 }, () => {}).map((_, age) => (
                  <option value={age} key={age}>
                    {age || "0-1"} an{addLetterSofPlural(age)}{" "}
                  </option>
                ))}
              </Select>
            </div>
          ))}
        </div>
      ))}
      {rooms?.length !== 4 && (
        <button
          className='flex items-center pt-3  justify-center'
          type='button'
          onClick={() => onChange && onChange(rooms?.concat({ ...DEFAULT_ROOM_DATA }))}>
          <PlusCircleIcon className='w-6 h-6 mr-2' />
          <div className='text-sm'>Ajouter une Chambre</div>
        </button>
      )}
    </div>
  );
};

export default GuestsInput;
