import { ISelectedArrangements } from "components/StayCardPricesTable/StayCardPricesTable";
import { ISearchResult } from "interfaces/searchResult";
import { useSearch } from "context/SearchContext";
import { FC, useState } from "react";
import { calculateRoomTotalPrice } from "utils/prices.utils";
import RoomCard from "./RoomCard";
import { IHotelDetails } from "interfaces/hotel";

type BookingRoomsProps = FC<{
  hotelDetails?: IHotelDetails;
  isHotelDetails?: boolean;
  selectedRoomType: number;
  onSelectRoomType: (roomType: number) => void;
  selectedArrangements: ISelectedArrangements;
  roomIndex: number;
  setSelectedArrangements: (data: ISelectedArrangements) => void;
  roomTypes: ISearchResult["occupancies"][0]["room_types"];
}>;

const BookingRooms: BookingRoomsProps = ({
  selectedRoomType,
  onSelectRoomType,
  selectedArrangements,
  roomIndex,
  setSelectedArrangements,
  roomTypes,
  isHotelDetails,
  hotelDetails,
}) => {
  const { userType } = useSearch();
  const [isExpanded, setIsExpanded] = useState(false);
  const stopSalesRooms = roomTypes.filter((room) => room.stop_sale);
  const forSalesRooms = roomTypes
    .filter((room) => !room.stop_sale && room.quantity)
    .sort((a, b) => Number(a.board?.[0].net) - Number(b.board?.[0].net));

  const overbookedRooms = roomTypes
    .filter((room) => !room.stop_sale && !room.quantity)
    .sort((a, b) => Number(a.board?.[0].net) - Number(b.board?.[0].net));

  const calculateRoomPrice = (
    room: ISearchResult["occupancies"][0]["room_types"][0]
  ) => {
    return calculateRoomTotalPrice(
      userType!,
      room,
      selectedArrangements?.[roomIndex]?.[room.data.id]?.boardId ||
        roomTypes[0].board[0].data.id
    );
  };

  return (
    <>
      <div className="">
        {forSalesRooms.map((el) => (
          <RoomCard
            key={el.data.id}
            hotelDetails={hotelDetails}
            isHotelDetails
            board={el.board}
            onSelectRoomType={() => onSelectRoomType(el.data.id)}
            isSelected={selectedRoomType === el.data.id}
            setSelectedArrangements={setSelectedArrangements}
            selectedArrangements={selectedArrangements}
            roomIndex={roomIndex}
            calculateRoomPrice={calculateRoomPrice(el)}
            className="mb-4"
            room={el}
            badgeDisplay={true}
          />
        ))}
        {overbookedRooms.map((el) => (
          <RoomCard
            key={el.data.id}
            hotelDetails={hotelDetails}
            isHotelDetails
            board={el.board}
            onSelectRoomType={() => onSelectRoomType(el.data.id)}
            isSelected={selectedRoomType === el.data.id}
            setSelectedArrangements={setSelectedArrangements}
            selectedArrangements={selectedArrangements}
            roomIndex={roomIndex}
            calculateRoomPrice={calculateRoomPrice(el)}
            className="mb-4"
            room={el}
            badgeDisplay={true}
          />
        ))}

        {stopSalesRooms[0] && (
          <RoomCard
            hotelDetails={hotelDetails}
            isHotelDetails
            onSelectRoomType={() => onSelectRoomType(stopSalesRooms[0].data.id)}
            isSelected={selectedRoomType === stopSalesRooms[0].data.id}
            isStopSales
            setSelectedArrangements={setSelectedArrangements}
            selectedArrangements={selectedArrangements}
            roomIndex={roomIndex}
            calculateRoomPrice={calculateRoomPrice(stopSalesRooms[0])}
            className="mb-4"
            room={stopSalesRooms[0]}
            badgeDisplay={true}
          />
        )}
        {isExpanded &&
          stopSalesRooms
            .slice(1)
            .map((el) => (
              <RoomCard
                key={el.data.id}
                hotelDetails={hotelDetails}
                isHotelDetails
                onSelectRoomType={() => onSelectRoomType(el.data.id)}
                isSelected={selectedRoomType === el.data.id}
                setSelectedArrangements={setSelectedArrangements}
                selectedArrangements={selectedArrangements}
                roomIndex={roomIndex}
                calculateRoomPrice={calculateRoomPrice(el)}
                className="mb-4"
                isStopSales
                room={el}
                badgeDisplay={true}
              />
            ))}
        {stopSalesRooms.length > 1 && (
          <button
            className="w-full h-full cursor-pointer flex justify-center"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <div className="flex items-center rounded-full px-4 py-2 text-primary-blue font-semibold">
              <div className="text-sm">
                {isExpanded ? "Réduire les chambres" : "Voir plus de chambres"}
              </div>
              <div className={`ml-3 text-xl transform transition-transform`}>
                {">"}
              </div>
            </div>
          </button>
        )}
      </div>
    </>
  );
};

export default BookingRooms;
