import { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import { useSearch } from "context/SearchContext";
import { ISearchDestinations } from "routers/types";

export interface LocationInputProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
  onInputDone?: (location?: string, slug_id?: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = "Destination",
  desc = "Ville ou nom d’hôtel",
  className = "nc-flex-1.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const { searchDestinations } = useSearch();

  useEffect(() => {
    if (!value) {
      const data = [...searchDestinations.cities, ...searchDestinations.hotels].find(
        ({ id, slug }: { id?: string; slug?: string }) => (id && id == defaultValue) || (slug && slug == defaultValue)
      );
      if (data) return setValue(data.name);
    }
  }, [defaultValue, searchDestinations]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string, locationId?: string, slug_id?: string) => {
    setValue(item);
    onInputDone && onInputDone(locationId, slug_id);
    setShowPopover(false);
  };

  const renderSearchDestinations = ({
    type,
    array,
  }:
    | {
        type: "cities";
        array?: ISearchDestinations["cities"];
      }
    | {
        type: "hotels";
        array?: ISearchDestinations["hotels"];
      }) => {
    const data = array || searchDestinations[type];
    return (
      <>
        {(!array || (array && array.length !== 0)) && (
          <h3 className='block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-primary-gray dark:text-neutral-100'>
            {type === "cities" ? "Ville" : "Hotels"}
          </h3>
        )}
        <div className='mt-2'>
          {data.map((el: { name: string; id?: string; slug?: string }) => (
            <span
              onClick={() => handleSelectLocation(el.name, el.id, el.slug)}
              key={el.name}
              className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'>
              <span className='block text-neutral-400'>
                <svg xmlns='http://www.w3.org/2000/svg' className='h-4 sm:h-6 w-4 sm:w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                </svg>
              </span>
              <span className=' block font-medium text-neutral-700 dark:text-primary-gray'>{el.name}</span>
            </span>
          ))}
        </div>
      </>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {renderSearchDestinations({
          type: "hotels",
          array: searchDestinations.hotels.filter(({ name }) => name.trim().toLowerCase().includes(value.trim().toLowerCase())),
        })}
        {renderSearchDestinations({
          type: "cities",
          array: searchDestinations.cities.filter(({ name }) => name.trim().toLowerCase().includes(value.trim().toLowerCase())),
        })}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}>
        <div className='text-neutral-300 dark:text-neutral-400'>
          <svg xmlns='http://www.w3.org/2000/svg' className='nc-icon-field' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
            />
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M15 11a3 3 0 11-6 0 3 3 0 016 0z' />
          </svg>
        </div>
        <div className='flex-grow'>
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-primary-gray dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={e => {
              setValue(e.currentTarget.value);
              onChange && onChange(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className='block mt-0.5 text-sm text-neutral-400 font-light '>
            <span className='line-clamp-1'>{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                onChange && onChange("");
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className='absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-primary-gray top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto'>
          {value ? (
            renderSearchValue()
          ) : (
            <>
              {renderSearchDestinations({ type: "hotels" })}
              {renderSearchDestinations({ type: "cities" })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationInput;
