import React, { useState } from "react";
import { IHotelDetails } from "interfaces/hotel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import visa from "../../images/payment/visa.png";
import mastercard from "../../images/payment/mastercard.png";
import d17 from "../../images/payment/d17.png";
import flouci from "../../images/payment/flouci.png";
import konnect from "../../images/payment/konnect.png";
import GeneralCondtions from "./ModalGeneralConditions";

const Footer: React.FC<{ hotelDetails: IHotelDetails }> = ({
  hotelDetails,
}) => {

  // Split by comma and trim each email, then join them with ', '
  const emailList = hotelDetails.emails.split(",").map((email) => email.trim());

  // Handler for email click
  const handleEmailClick = (email: string) => {
    window.open(`mailto:${email}`, "_blank");
  };

  return (
    <footer className="bg-white mt-20 text-gray-800">
      <div className="py-6 px-12">
        <div className="flex flex-wrap justify-between">
          {/* Logo and Company Info */}
          <div className="w-full lg:w-3/12 mb-10 lg:mb-0">
            <img
              src={hotelDetails.logo}
              alt="Site Logo"
              className="h-12 mb-4"
              loading="lazy"
            />
            <div className="text-xs">
              <div>
                Réservez sur le Site Officiel de {hotelDetails.name} | Meilleur
                Prix Garanti
              </div>
              <div className="text-xs fw-500 mt-5">
                Paiement en ligne sécurisé
              </div>
              <br />
              <div className="flex w-full space-x-4 h-4">
                <img src={visa} alt="Visa" loading="lazy" />
                <img src={mastercard} alt="MasterCard" loading="lazy" />
                <img src={d17} alt="D17" loading="lazy" />
                <img src={flouci} alt="Flouci" loading="lazy" />
                <img src={konnect} alt="Konnect" loading="lazy" />
              </div>
              <br />
            </div>
          </div>
          {/* Quick Links */}
          <div className="w-full lg:w-2/12 mb-10 lg:mb-0">
            <h3 className="font-semibold text-sm mb-4">Liens Rapides</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="#rooms_available"
                  className="text-xs hover:text-primary-blue"
                >
                  Chambres
                </a>
              </li>
              <li>
                <a
                  href="#services_equipment"
                  className="text-xs hover:text-primary-blue"
                >
                  Services et équipements
                </a>
              </li>
              <li>
                <a
                  href="#accommodation"
                  className="text-xs hover:text-primary-blue"
                >
                  Hébergement
                </a>
              </li>
              <li>
                <a
                  href="#general_infos"
                  className="text-xs hover:text-primary-blue"
                >
                  Informations générales
                </a>
              </li>
              <GeneralCondtions />
            </ul>
          </div>
          {/* Contact Info */}
          <div className="w-full lg:w-2/12 mb-10 lg:mb-0">
            <h3 className="font-semibold text-sm mb-4">Contactez-Nous</h3>
            <ul className="space-y-2 text-xs">
              <li className="space-x-2">
                <FontAwesomeIcon icon={faEnvelope} />
                {emailList.map((email, index) => (
                  <React.Fragment key={index}>
                    <button
                      onClick={() => handleEmailClick(email)}
                      className="hover:text-black text-blue-900 bg-transparent border-0 p-0 cursor-pointer"
                    >
                      {email}
                    </button>
                    {index < emailList.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </li>
              <li className="space-x-2">
                <FontAwesomeIcon icon={faPhone} />
                <span>{hotelDetails.phones}</span>
              </li>
              <li className="space-x-2">
                <FontAwesomeIcon icon={faMapPin} />
                <span>
                  {hotelDetails.name}, {hotelDetails.address},{" "}
                  {hotelDetails.city.city}, Tunisie
                </span>
              </li>
            </ul>
            <div className="social-icons">
              <a
                href={"facebook_link"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook text-red-resa"></i>
              </a>
              <a
                href={"instagram_link"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram text-red-resa"></i>
              </a>
            </div>
          </div>

          {/* Map */}
          <div className="w-full lg:w-4/12  footer-section">
            <iframe
              title="ma9"
              src={
                "https://www.google.com/maps?q=Kinko%20Agence%2C%20R%C3%A9sidence%20Floralys%20Riadh%20el%20Andalous%202080%20Ariana%2C%20Tunisie&output=embed"
              }
              width="100%"
              height="200px"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div className="footer-bottom bg-secondary-gray">
        <p className="text-black text-center fw-500 text-sm">
          © All Rights Reserved - Made with ❤️ by{" "}
          <a href="https://kinkobooking.com/">Kinko Booking</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
