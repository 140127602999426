import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionGridHasMap from "./SectionGridHasMap";

export interface ListingStayMapPageProps {
  className?: string;
}

const ListingStayMapPage: FC<ListingStayMapPageProps> = ({ className = "" }) => {
  return (
    <div className={`nc-ListingStayMapPage relative ${className}`} data-nc-id='ListingStayMapPage'>
      <Helmet>
        <title>Hotels</title>
      </Helmet>
      <div className='container pb-24 lg:pb-28 2xl:pl-10 xl:pr-0 xl:max-w-none'>
        <SectionGridHasMap hotels={[]} />
      </div>
    </div>
  );
};

export default ListingStayMapPage;
