import React from "react";
import * as Constants from "./BadgeConstants";
import { currentUrlParams } from "utils/getUrlParams";
import moment from "moment";
import Badge, { BadgeProps } from "./Badge";

interface AvailabilityBadgeProps {
  stopSale: boolean;
  minStay: number;
  rateType: string;
}

const calculateNumberOfNights = (
  startDateParam: string,
  endDateParam: string
): number => {
  const startDate = moment(startDateParam, "DD-MM-YYYY");
  const endDate = moment(endDateParam, "DD-MM-YYYY");

  if (!startDate.isValid() || !endDate.isValid()) {
    return 0;
  }

  const differenceInTime = endDate.diff(startDate, "days");
  return differenceInTime;
};

const AvailabilityBadge: React.FC<AvailabilityBadgeProps> = ({
  stopSale,
  minStay,
  rateType,
}) => {
  const currentParams = currentUrlParams();
  const startDateParam = moment(currentParams.start).format("DD-MM-YYYY");
  const endDateParam = moment(currentParams.end).format("DD-MM-YYYY");

  const numberOfNights =
    startDateParam && endDateParam
      ? calculateNumberOfNights(startDateParam, endDateParam)
      : 0;

  let label: BadgeProps["label"];
  let type: BadgeProps["type"];

  if (stopSale) {
    label = Constants.STOP_SALE_TEXT;
    type = "error";
  } else if (rateType === "available") {
    label = Constants.AVAILABLE_TEXT;
    type = "success";
  } else if (rateType === "onrequest") {
    if (minStay > numberOfNights) {
      label = Constants.MINIMUM_NIGHTS_TEXT(minStay);
      type = "error";
    } else {
      label = Constants.ON_REQUEST_TEXT;
      type = "info";
    }
  }

  return <Badge size="small" label={label} type={type} />;
};

export default AvailabilityBadge;
