import { FC, useState } from "react";

const NUMBER_OF_WORDS = 50;

const SeeMoreText: FC<{
  text: string;
  numberOfWords?: number;
  handleSeeMore?: () => void;
}> = ({
  text,
  numberOfWords = NUMBER_OF_WORDS,
  handleSeeMore,
}): JSX.Element => {
  const [showAllText, setShowAllText] = useState(false);

  const onClick = () => setShowAllText(!showAllText);

  // Fonction pour formater le texte
  const formatText = (text: string): JSX.Element[] => {
    return text.split(". ").map((sentence, index) => {
      // Détecter les éléments séparés par des tirets dans chaque phrase
      const formattedSentence = sentence.split("-").map((part, idx) => (
        <div key={idx}>
          {idx === 0 ? (
            <span>{part.trim() + (part.trim().endsWith(".") ? "" : ".")}</span>
          ) : (
            <div className="pl-4">- {part.trim()}</div>
          )}
        </div>
      ));
      return <div key={index} className="mb-2">{formattedSentence}</div>;
    });
  };

  const truncatedText = text
    .split(" ")
    .slice(0, (!showAllText && numberOfWords !== -1) ? numberOfWords : undefined)
    .join(" ");

  return (
    <>
      {showAllText ? formatText(text) : truncatedText}
      {(text.split(" ").length > numberOfWords && numberOfWords !== -1) ? (
        <>
          {numberOfWords ? <span>...</span> : null}
          <div
            className="text-xs cursor-pointer underline text-black mt-3"
            onClick={handleSeeMore || onClick}
          >
            {showAllText ? "< Réduire" : "En savoir plus >"}
          </div>
        </>
      ) : null}
    </>
  );
};

export default SeeMoreText;
