import { useCallback, useEffect, useState } from "react";

export function useSearchResult(fn: Function, dependencies = []) {
  const { execute, ...state } = useSearchResultInternal(fn, dependencies, true);
  useEffect(() => {
    execute();
  }, [execute]);
  return state;
}

export function useSearchResultFn(fn: Function, dependencies = []) {
  return useSearchResultInternal(fn, dependencies, false);
}

function useSearchResultInternal(fn: Function, dependencies = [], initialLoading = false) {
  const [loading, setLoading] = useState(initialLoading);
  const [error, setError] = useState();
  const [searchResult, setSearchResult] = useState();

  const execute = useCallback((...params: any) => {
    setLoading(true);
    return fn(...params)
      .then((data: any) => {
        setSearchResult(data);
        setError(undefined);
        return data;
      })
      .catch((error: any) => {
        setSearchResult(undefined);
        setError(error);
        return Promise.reject(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, dependencies);

  return { loading, error, searchResult, execute };
}
