import { IHotelDetails } from "interfaces/hotel";
import { useEffect, useState } from "react";
import { useSearchParam } from "react-use";
import { useLocation, useParams } from "react-router-dom";
import { useSearch } from "context/SearchContext";
import { getHotelDetailsService } from "api.service";

export const useFetchHotelDetails = (): {
  hotelDetails?: IHotelDetails;
  hotelDetailsLoading: boolean;
} => {
  const [hotelDetails, setHotelDetails] = useState<IHotelDetails>();
  const [loading, setLoading] = useState(true);
  const hotelSlugParam = useSearchParam("hotelSlug")!;
  const { hotelSlug }: { hotelSlug: string } = useParams();
  const { search } = useLocation();
  const { searchData } = useSearch();

  const fetchHotelDetails = async (isUpdateSearchResult?: boolean) => {
    setLoading(true);
    try {
      const res = await getHotelDetailsService(
        isUpdateSearchResult ? searchData.hotel_slug! : hotelSlug! || hotelSlugParam!
      );
      setHotelDetails(res);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) {
      fetchHotelDetails(true);
    }
  }, [search, hotelSlug]);
  useEffect(() => {
    fetchHotelDetails();
  }, []);
  return { hotelDetails, hotelDetailsLoading: loading };
};
