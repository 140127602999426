import { useState, useEffect, useRef, FC } from "react";
import { useSearch } from "context/SearchContext";
import { ISearchDestinations } from "routers/types";
import { SearchIcon } from "@heroicons/react/outline";

interface Props {
  onClick?: () => void;
  onChange?: (location?: string, slug_id?: string) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
  isActive?: boolean;
  setFieldNameShow?: (name: "location" | "dates" | "guests") => void;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "United States",
  headingText = "Destination?",
  isActive,
  setFieldNameShow,
}) => {
  const [value, setValue] = useState("");
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const { searchDestinations } = useSearch();

  useEffect(() => {
    const data = [...searchDestinations.cities, ...searchDestinations.hotels].find(
      ({ id, slug }: { id?: string; slug?: string }) => (id && id == defaultValue) || (slug && slug == defaultValue)
    );
    if (data) return setValue(data.name);
  }, [defaultValue]);

  useEffect(() => {
    if (isActive) {
      setValue("");
    } else {
      const data = [...searchDestinations.cities, ...searchDestinations.hotels].find(
        ({ id, slug }: { id?: string; slug?: string }) => (id && id == defaultValue) || (slug && slug == defaultValue)
      );
      if (data) return setValue(data.name);
    }
  }, [isActive]);

  const handleSelectLocation = (item: string, locationId?: string, slug_id?: string) => {
    // DO NOT REMOVE SETTIMEOUT FUNC
    setTimeout(() => {
      setValue(item);
      onChange && onChange(locationId, slug_id);
    }, 0);
  };

  const renderSearchDestinations = ({
    type,
    array,
  }:
    | {
        type: "cities";
        array?: ISearchDestinations["cities"];
      }
    | {
        type: "hotels";
        array?: ISearchDestinations["hotels"];
      }) => {
    const data = array || searchDestinations[type];
    return (
      <>
        {(!array || (array && array.length !== 0)) && (
          <h3 className='block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-primary-gray dark:text-neutral-100'>
            {type === "cities" ? "Ville" : "Hotels"}
          </h3>
        )}
        <div className='mt-2'>
          {data.map((el: { name: string; id?: string; slug?: string }) => (
            <span
              onClick={() => handleSelectLocation(el.name, el.id, el.slug)}
              key={el.name}
              className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'>
              <span className='block text-neutral-400'>
                <svg xmlns='http://www.w3.org/2000/svg' className='h-4 sm:h-6 w-4 sm:w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={1.5} d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' />
                </svg>
              </span>
              <span className=' block font-medium text-neutral-700 dark:text-primary-gray'>{el.name}</span>
            </span>
          ))}
        </div>
      </>
    );
  };

  if (!isActive) {
    return (
      <button
        className={`w-full flex justify-between text-sm font-medium p-4 items-center`}
        onClick={() => setFieldNameShow && setFieldNameShow("location")}>
        <span className='text-neutral-400'>Destination</span>
        <span>{value || "Ville ou nom d’hôtel"}</span>
      </button>
    );
  }

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className='p-5'>
        <span className='block font-semibold text-xl sm:text-2xl'>{headingText}</span>
        <div className='relative mt-5'>
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Ville ou nom d’hôtel"}
            onChange={e => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className='absolute right-2.5 top-1/2 -translate-y-1/2'>
            <SearchIcon className='w-5 h-5 text-neutral-700 dark:text-neutral-400' />
          </span>
        </div>
        <div className='mt-7'>
          {renderSearchDestinations({ type: "hotels" })}
          {renderSearchDestinations({ type: "cities" })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
