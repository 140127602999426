import { FC } from "react";

const IconAndText: FC<{ name: string; icon: string }> = ({ name, icon }) => {
  return (
    <div key={name} className="flex items-center space-x-1 leading-4">
      <i className={`text-base las ${icon}`} aria-hidden="true"></i>
      <span className="text-xs">{name}</span>
    </div>
  );
};

export default IconAndText;
