import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logos/Primary Logo.png";
import logoLightImg from "images/logo-light.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-24",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-blue focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight /> */}
      {/* <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? ( */}
      <img
        className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
        src={img}
        alt="Logo"
      />
      {/* // ) : (
      //   "Logo Here"
      // )} */}
      {/* {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
