import StartRating from "components/StartRating/StartRating";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { addLetterSofPlural } from "utils/string";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import moment from "moment";
import { useSearch } from "context/SearchContext";

export interface PayPageProps {
  className?: string;
  cover?: string;
  hotelType?: string;
  city?: string;
  name?: string;
  address?: string;
  bookingDate?: string;
  nbRooms?: number;
  nbPersons?: number;
  date?: DateRage;
  price?: string;
  paidAmount?: number;
  reservationNumber?: string;
  restAmountToPay?: number;
  reservationStatus?: "pending" | "voucher";
  cancellation_policies: {
    type: "free_cancellation" | "free_and_penalty_cancellation";
    until: string;
    amount: number;
    message?: string | undefined;
  };
}

const PayPage: FC<PayPageProps> = ({
  className = "",
  cover,
  hotelType,
  city,
  name,
  address,
  nbRooms,
  nbPersons,
  date,
  bookingDate,
  price,
  paidAmount,
  reservationNumber,
  reservationStatus,
  restAmountToPay,
  cancellation_policies,
}) => {
  const { searchData } = useSearch()
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-2xl font-semibold">
          {reservationStatus === "pending"
            ? "Demande de réservation"
            : "Félicitations 🎉"}{" "}
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-lg font-semibold">Votre séjour</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={cover} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {hotelType} - {city}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {name}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {address}
              </span>
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold capitalize">
                  {/* Aug 12 - 16, 2021 */}
                  {date && converSelectedDateToString(date)}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">
                  <div className="">
                    {nbRooms} chambre
                    {addLetterSofPlural(nbRooms || 0)} / {nbPersons} personne
                    {addLetterSofPlural(nbPersons || 0)}
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-lg font-semibold">Détails de la réservation</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Référence</span>
              <span className="flex-1 font-medium text-neutral-black  dark:text-neutral-100">
                {reservationNumber}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date de réservation</span>
              <span className="flex-1 font-medium text-neutral-black  dark:text-neutral-100">
                {moment(bookingDate).format("DD MMM, YYYY")}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total de la réservation</span>
              <span className="flex-1 font-medium text-neutral-black  dark:text-neutral-100">
                {price} {searchData?.currency}
              </span>
            </div>
            {paidAmount ? (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Montant Payé</span>
                <span className="flex-1 font-medium text-neutral-black  dark:text-neutral-100">
                  {paidAmount || 0} {searchData?.currency}
                </span>
              </div>
            ) : null}
            {reservationStatus == "voucher" &&
              restAmountToPay &&
              restAmountToPay > 0 ? (
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <span className="flex-1">Reste à payer</span>
                <span className="flex-1 font-medium text-neutral-black  dark:text-neutral-100">
                  {restAmountToPay} {searchData?.currency}
                </span>
              </div>
            ) : null}
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Méthode de paiement</span>
              <span className="flex-1 font-medium text-neutral-black  dark:text-neutral-100">
                Carte de crédit
              </span>
            </div>
          </div>
          <div className="mt-4 flex-1 font-medium text-neutral-black  dark:text-neutral-100">
            {reservationStatus === "pending"
              ? "Votre demande de réservation a été envoyée à l’établissement. Vous allez recevoir une réponse par email."
              : "Un email vous sera envoyé avec le bon voucher."}
          </div>
        </div>
        {/* <div>
          <ButtonPrimary href="/">Explore more stays</ButtonPrimary>
        </div> */}
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className=" mx-auto">{renderContent()}</div>
        {cancellation_policies?.message && (
          <div className="w-full mt-4 flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 lg:space-y-8 space-y-5 px-0 sm:p-6 xl:p-8">
            <div className="lg:text-lg sm:text-sm text-sm mb-2  text-primary-blue font-bold ">
              Conditions d'annulation
            </div>

            <div className="">
              {cancellation_policies?.message && (
                <div className="text-sm text-red-600">
                  {cancellation_policies?.message}
                </div>
              )}
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default PayPage;
