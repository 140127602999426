import React, { FC } from "react";

export interface CheckboxProps {
  label?: any;
  subLabel?: string;
  className?: string;
  name: string;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  isDisabled?: boolean;
  onlyLabel?: boolean;
  labelClassName?: string;
  inputClassName?: string;
}

const Checkbox: FC<CheckboxProps> = ({
  subLabel = "",
  label = "",
  name,
  className = "",
  defaultChecked,
  checked,
  onChange,
  isDisabled,
  onlyLabel,
  labelClassName,
  inputClassName,
}) => {
  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      {!onlyLabel && (
        <input
          checked={checked}
          id={name}
          name={name}
          type='checkbox'
          className={`focus:ring-action-primary h-6 w-6 text-primary-700 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500 ${inputClassName} `}
          onChange={e => onChange && onChange(e.target.checked)}
          disabled={isDisabled}
        />
      )}
      {label && (
        <label {...(!isDisabled ? { htmlFor: name } : {})} className='ml-2.5 flex flex-col flex-1 justify-center'>
          <span className={`text-neutral-black  dark:text-neutral-100 ${labelClassName}`}>{label.name || label}</span>
          {subLabel && <p className='mt-1 text-neutral-500 dark:text-neutral-400 text-sm font-light'>{subLabel}</p>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
