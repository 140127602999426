import Header3 from "components/Header/Header3";
import { useSearch } from "context/SearchContext";
import { ReactNode } from "react";
import { useSearchParam } from "react-use";
import { addLetterSofPlural } from "utils/string";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  hotelLength?: number;
}

const Heading2: React.FC<Heading2Props> = ({ className = "", heading = "Stays in Tokyo", subHeading, hotelLength }) => {
  const { searchDestinations } = useSearch();
  const destinationId = useSearchParam("location");

  const cityName = destinationId && searchDestinations.cities.find(el => el.id == destinationId)?.name;

  return (
    <div className={`mb-3 lg:mb-6 mt-4 flex flex-col border-b pb-3 ${className}`}>
      <h2 className='text-lg '>
        {hotelLength} établissement{addLetterSofPlural(hotelLength!)} à {cityName}{" "}
      </h2>
      <div className='w-full mt-2'>
        <Header3 isOnlySearch destinationName={cityName || ""} />
      </div>
      {/* {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          233 stays
          <span className="mx-2">·</span>
          Aug 12 - 18
          <span className="mx-2">·</span>2 Guests
        </span>
      )} */}
    </div>
  );
};

export default Heading2;
