import { StarIcon } from "@heroicons/react/solid";
import React, { FC } from "react";

export interface StartRatingProps {
  className?: string;
  point?: number;
  reviewCount?: number;
  starClassName?: string;
}

const StartRating: FC<StartRatingProps> = ({ className = "", point = 4.5, reviewCount = 112, starClassName = "" }) => {
  return (
    <div className={`nc-StartRating flex items-center text-sm  ${className}`} data-nc-id='StartRating'>
      {Array.from({ length: point }, () => {}).map((_, idx) => (
        <StarIcon key={idx} className={`w-4 h-4 text-secondary-yellow ${starClassName} `} />
      ))}
      {/* <span className="font-medium ">{point}</span>
      {reviewCount ? <span className="text-neutral-500 dark:text-neutral-400">
        ({reviewCount})
      </span> : null} */}
    </div>
  );
};

export default StartRating;
