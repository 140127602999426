import { useContext, useEffect, useState, createContext } from "react";
import { useSearchParam } from "react-use";
import {
  getDestinationsService,
  getMyHotelInformation,
  getUserTypeService,
  postSearchHotelsService,
} from "api.service";
import {
  DEFAULT_ROOM_DATA,
  IRoom,
} from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import { ISearchResult } from "interfaces/searchResult";
import { ISearchDestinations, TUserType } from "routers/types";
import { currentUrlParams } from "utils/getUrlParams";
import moment from "moment";
import { useSearchResultFn } from "hooks/useSearchApi";

export interface ISearch {
  location?: string;
  hotel_slug?: string;
  dateRange: DateRage;
  currency: string;
  rooms: IRoom[];
  // guest: string;
}

interface SearchContextValues {
  myHotelInformation?: ISearchResult["data"];
  userType?: TUserType;
  searchData: ISearch;
  setSearchData: Function;
  getSearchPathWithQuery: (path: string, isHotelDetails?: boolean) => string;
  searchDestinations: ISearchDestinations;
  searchResult: ISearchResult | undefined;
  setSearchResult: Function;
  handleSearch: Function;
}

const SearchContext = createContext<SearchContextValues>({
  searchData: {
    location: "",
    dateRange: {
      startDate: null,
      endDate: null,
    },
    currency: "",
    rooms: [],
  },
  searchDestinations: { hotels: [], cities: [] },
  setSearchData: () => {},
  getSearchPathWithQuery: () => "",
  searchResult: ({} as ISearchResult) || undefined,
  setSearchResult: () => undefined,
  handleSearch: () => undefined,
});

export function SearchContextProvider({ children }: { children: any }) {
  const [searchDestinations, setSearchDestinations] =
    useState<ISearchDestinations>({
      cities: [],
      hotels: [],
    });
  const [myHotelInformation, setMyHotelInformation] =
    useState<ISearchResult["data"]>();
  const locationId = useSearchParam("location")!;
  const { key, hotelSlug } = currentUrlParams();
  const [searchData, setSearchData] = useState<ISearch>({
    location: locationId,
    dateRange: {
      startDate: moment(),
      endDate: moment().add(1, "day"),
    },
    currency: "TND",
    rooms: [DEFAULT_ROOM_DATA],
  });
  const [userType, setUserType] = useState<TUserType>();
  const [searchResult, setSearchResult] = useState();
  const hotelService = useSearchResultFn(postSearchHotelsService);

  const getSearchPathWithQuery = (path: string) => {
    const startDate = moment(searchData.dateRange.startDate).format(
      "YYYY-MM-DD"
    );
    const endDate = moment(searchData.dateRange.endDate).format("YYYY-MM-DD");
    const rooms = searchData.rooms.length;

    let queryString = `start=${startDate}&end=${endDate}&rooms=${rooms}`;

    searchData.rooms.forEach((room, index) => {
      queryString += `&adults${index + 1}=${room.guestAdults}`;
      if (room.guestChildren.length > 0) {
        queryString += `&children${index + 1}=${room.guestChildren.join(",")}`;
      }
    });

    return `${path}?${queryString}`;
  };

  const fetchSearchDestinations = async (key?: string | null) => {
    try {
      const res = await getDestinationsService(key);
      setSearchDestinations(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMyHotelInformation = async (key?: string | null) => {
    try {
      const res = await getMyHotelInformation(key);
      setMyHotelInformation(res);
    } catch (error) {}
  };

  const fetchUserType = async () => {
    try {
      const res = await getUserTypeService(key);
      if (res === "Agency") fetchSearchDestinations(key);
      if (res === "Hotel") {
        fetchMyHotelInformation(key);
      }

      setUserType(res);
    } catch (error) {}
  };

  const handleSearch = () => {
    const start_date = moment(searchData.dateRange.startDate).format(
      "YYYY-MM-DD"
    );
    const end_date = moment(searchData.dateRange.endDate).format("YYYY-MM-DD");
    const occupancies = searchData.rooms.map((room) => ({
      adult: room.guestAdults,
      child: room.guestChildren.length,
      child_ages: room.guestChildren,
    }));

    return hotelService
      .execute({
        hotel: hotelSlug,
        start_date,
        end_date,
        currency: searchData.currency,
        occupancies,
      })
      .then((res: any) => {
        setSearchResult(res[0]);
        return res;
      });
  };

  useEffect(() => {
    fetchUserType();
  }, []);

  return (
    <SearchContext.Provider
      value={{
        myHotelInformation,
        userType,
        searchDestinations,
        searchData,
        setSearchData,
        getSearchPathWithQuery,
        searchResult,
        setSearchResult,
        handleSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  return useContext(SearchContext);
}
