import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Carousel } from 'flowbite-react';



export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
  imageClassName?: string;
  onClickImage?: () => void;
}

const GallerySlider: FC<GallerySliderProps> = ({
  galleryImgs,
  onClickImage
}) => {

  const LeftControl = () => {
    return <div className="h-7 w-7 bg-white rounded-full flex items-center justify-center overflow-hidden pointer-events-auto">
      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" className="h-4 w-4 text-gray-800" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7"></path>
      </svg>
    </div>
  }

  const RightControl = () => {
    return <div className="h-7 w-7 bg-white rounded-full flex items-center justify-center overflow-hidden pointer-events-auto">
      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" aria-hidden="true" className="h-4 w-4 text-gray-800" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"></path>
      </svg>
    </div>
  }


  return (
    <div className="absolute inset-0 overflow-hidden">
      <div className="w-full h-10 bg-gradient-to-t from-neutral-900 absolute z-20 bottom-0"></div>
      <Carousel rightControl={<RightControl />} leftControl={<LeftControl />} slide={false}>
        {galleryImgs.map((img, idx) => <NcImage key={idx} src={img} loading='lazy' containerClassName='h-full' className="h-full object-cover" onClick={onClickImage} />)}
      </Carousel>

    </div>
  );
};

export default GallerySlider;
