import axios from "axios";
import { IPostInitBookingServiceParams, IPostInitBookingServiceResponse } from "interfaces/booking";
import { IHotelDetails } from "interfaces/hotel";
import { IPostSearchHotelsServiceParams, ISearchResult } from "interfaces/searchResult";
import { ISearchDestinations, TUserType } from "routers/types";

// const key =
//   localStorage.getItem("key")
// "Qi7ET4mYd3M5GC3Cstjvy8NP";
// "AcB6uR1QVQRamWhKfoHwFnKG";

export const getDestinationsService = (initKey?: string | null): Promise<ISearchDestinations> => {
  const key = localStorage.getItem("key");
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}api/${process.env.REACT_APP_API_VERSION}/destinations?key=${
        initKey || key
      }`
    )
    .then(res => res.data);
};

export const getMyHotelInformation = (initKey?: string | null): Promise<ISearchResult["data"]> => {
  const key = localStorage.getItem("key");
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}api/${process.env.REACT_APP_API_VERSION}/hotel_data/?key=${
        initKey || key
      }`
    )
    .then(res => res.data);
};

export const getUserTypeService = (initKey?: string | null): Promise<TUserType> => {
  const key = localStorage.getItem("key");
  return axios
    .get(
      `${process.env.REACT_APP_RAILS_API_URL}api/${
        process.env.REACT_APP_API_VERSION
      }/users/user_type?key=${initKey || key}`
    )
    .then(res => {
      if (initKey) localStorage.setItem("key", initKey);
      return res.data.user_type;
    });
};

export const postGetHotelRoomsService = (
  search: IPostSearchHotelsServiceParams,
  hotelId: string
): Promise<ISearchResult[]> => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/${
        process.env.REACT_APP_API_VERSION
      }/search?key=${localStorage.getItem("key")}`,
      {
        search: { ...search, hotel: hotelId, destination: undefined },
      }
    )
    .then(res => {
      return res.data.hotels;
    });
};

export const getHotelDetailsService = (slug: string): Promise<IHotelDetails> => {
  const key = localStorage.getItem("key");
  if (slug) {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}api/${process.env.REACT_APP_API_VERSION}/hotels/${slug}?key=${key}`
      )
      .then(res => {
        return res.data;
      });
  } else {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}api/${process.env.REACT_APP_API_VERSION}/hotel_data/?key=${key}`
      )
      .then(res => {
        return res.data;
      });
  }
};

export const postSearchHotelsService = (
  search: IPostSearchHotelsServiceParams
): Promise<ISearchResult[]> => {
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}api/${
        process.env.REACT_APP_API_VERSION
      }/search?key=${localStorage.getItem("key")}`,
      {
        search,
      }
    )
    .then(res => {
      return res.data.hotels;
    });
};

export const postInitBookingService = (
  reservation: IPostInitBookingServiceParams
): Promise<IPostInitBookingServiceResponse> => {
  const key = localStorage.getItem("key");
  return axios
    .post(
      `${process.env.REACT_APP_RAILS_API_URL}api/${process.env.REACT_APP_API_VERSION}/bookings/init?key=${key}`,
      {
        reservation,
      }
    )
    .then(res => {
      return res.data;
    });
};

export const getBookingDetailsService = (
  slug: string
): Promise<IPostInitBookingServiceResponse> => {
  const key = localStorage.getItem("key");
  return axios
    .get(
      `${process.env.REACT_APP_RAILS_API_URL}api/${process.env.REACT_APP_API_VERSION}/bookings/${slug}?key=${key}`
    )
    .then(res => {
      return res.data;
    });
};

export const postCreateBokingService = (slug: string, reservation: any) => {
  const key = localStorage.getItem("key");
  return axios
    .post(
      `${process.env.REACT_APP_RAILS_API_URL}api/${process.env.REACT_APP_API_VERSION}/bookings/${slug}/submit?key=${key}`,
      {
        reservation,
      }
    )
    .then(res => {
      return res.data;
    });
};
