import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { useSearch } from "context/SearchContext";

const Page404: React.FC<{ message?: string; image?: string }> = ({ message, image }) => {
  const { myHotelInformation } = useSearch();

  return (
    <div className='nc-Page404'>
      {/* <Helmet>
        <title>404 || Booking React Template</title>
      </Helmet> */}
      <div className='container relative pt-5 pb-16 lg:pb-20 lg:pt-5'>
        {/* HEADER */}
        <header className='text-center max-w-2xl mx-auto space-y-2'>
          <NcImage src={image ?? I404Png} />
          <span className='block text-sm text-primary-gray sm:text-base dark:text-primary-gray tracking-wider font-medium'>
            {message ?? "THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST."}{" "}
          </span>
          <div className='pt-8'>
            <ButtonPrimary href={"/hotel-detail/" + myHotelInformation?.slug}>Revenir à la page d’accueil</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Page404;
