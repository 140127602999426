import { FC, useEffect, useRef, useState } from "react";
import Logo from "shared/Logo/Logo";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import HeroSearchForm, {
  SearchTab,
} from "components/HeroSearchForm2/HeroSearchForm";
import { useLocation } from "react-router-dom";
import { StaySearchFormFields } from "components/HeroSearchForm2/StaySearchForm";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import SocialsList from "shared/SocialsList/SocialsList";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useSearch } from "context/SearchContext";
import moment from "moment";
import { addLetterSofPlural } from "utils/string";

interface Header3Props {
  className?: string;
  isOnlySearch?: boolean;
  destinationName?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const Header3: FC<Header3Props> = ({
  className = "",
  isOnlySearch,
  destinationName,
}) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const [showHeroSearch, setShowHeroSearch] =
    useState<StaySearchFormFields | null>();
  const [currentTab, setCurrentTab] = useState<SearchTab>("Stays");
  const { userType, searchData } = useSearch();

  useOutsideAlerter(headerInnerRef, () => {
    setShowHeroSearch(null);
    setCurrentTab("Stays");
  });

  let location = useLocation();

  useEffect(() => {
    setShowHeroSearch(null);
  }, [location]);

  // HIDDEN WHEN SCROLL EVENT
  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };

  const handleHideSearchForm = () => {
    let currentScrollPos = window.pageYOffset;
    if (
      WIN_PREV_POSITION - currentScrollPos > 100 ||
      WIN_PREV_POSITION - currentScrollPos < -100
    ) {
      setShowHeroSearch(null);
    } else {
      return;
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  //
  const renderHeroSearch = () => {
    return (
      <div
        className={`z-50 bg-white absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${
          showHeroSearch
            ? "visible"
            : "-translate-x-0 -translate-y-[90px] scale-x-[0.395] scale-y-[0.6] opacity-0 invisible pointer-events-none"
        }`}
      >
        <div className={`w-full max-w-4xl mx-auto pb-6`}>
          <HeroSearchForm
            defaultFieldFocus={showHeroSearch || undefined}
            onTabChange={setCurrentTab}
            defaultTab={currentTab}
          />
        </div>
      </div>
    );
  };

  const renderButtonOpenHeroSearch = () => {
    return (
      <div
        className={`w-full relative flex items-center justify-between border border-neutral-200 dark:border-neutral-6000 rounded-full shadow hover:shadow-md transition-all ${
          showHeroSearch
            ? "-translate-x-0 translate-y-20 scale-x-[2.55] scale-y-[1.8] opacity-0 pointer-events-none invisible"
            : "visible"
        }`}
      >
        <div className="flex items-center font-medium text-sm">
          {userType === "Agency" && (
            <span
              onClick={() => setShowHeroSearch("location")}
              className="block pl-5 pr-4 cursor-pointer py-3"
            >
              {destinationName || "Location"}
            </span>
          )}
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            onClick={() => setShowHeroSearch("dates")}
            className="block px-4 cursor-pointer py-3 "
          >
            {moment(searchData.dateRange.startDate).isValid() &&
            moment(searchData.dateRange.endDate).isValid()
              ? `${moment(searchData.dateRange.startDate).format(
                  "DD/MM"
                )} - ${moment(searchData.dateRange.endDate).format("DD/MM")}`
              : "Check In"}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>
          <span
            onClick={() => {
              setShowHeroSearch("guests");
            }}
            className="block px-4 cursor-pointer font-normal py-3"
          >
            {searchData.rooms.length > 0 ? searchData.rooms.length : "Add"}{" "}
            Chambre
            {addLetterSofPlural(searchData.rooms.length)}
          </span>
        </div>

        <div
          className="flex-shrink-0 ml-auto pr-2 cursor-pointer"
          onClick={() => setShowHeroSearch("location")}
        >
          <span className="w-8 h-8 flex items-center justify-center rounded-full bg-primary-blue  text-white">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
              ></path>
            </svg>
          </span>
        </div>
      </div>
    );
  };

  if (isOnlySearch) {
    return (
      <div className="">
        <div className="hidden lg:block w-fit">
          {renderButtonOpenHeroSearch()}
        </div>
        <div className="lg:hidden w-full max-w-lg mx-auto">
          <HeroSearchForm2MobileFactory destinationName={destinationName} />
        </div>
        {renderHeroSearch()}
      </div>
    );
  }

  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0  transition-opacity will-change-[opacity] 
          ${
            showHeroSearch
              ? "visible"
              : "invisible opacity-0 pointer-events-none"
          }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header
        ref={headerInnerRef}
        className={`sticky top-0 z-40  ${className}`}
      >
        <div
          className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity]
          ${showHeroSearch ? "duration-75" : ""} 
          ${
            showHeroSearch
              ? currentTab === "Cars" || currentTab === "Flights"
                ? "scale-y-[4.4]"
                : "scale-y-[3.4]"
              : ""
          }`}
        ></div>
        <div className="relative px-4 lg:container h-[88px] flex">
          <div className="flex-1 flex items-center justify-between w-full">
            <div className="relative z-10  md:flex ">
              <Logo className="w-auto mr-3" />
            </div>

            {location.pathname !== "/" && (
              <div className="lg:block flex-[2] lg:flex-none mx-auto">
                <div className="hidden lg:block">
                  {renderButtonOpenHeroSearch()}
                </div>
                <div className="lg:hidden w-full max-w-lg mx-auto">
                  <HeroSearchForm2MobileFactory />
                </div>
                {renderHeroSearch()}
              </div>
            )}

          
            {/* NAV */}
            <div className=" md:flex relative z-10 items-center justify-end text-neutral-700 dark:text-neutral-100">
              <div className="items-center flex space-x-1">
                <div className="pr-1.5 hidden lg:block">
                  <SocialsList />
                </div>
                <div className="px-3 hidden lg:block">
                  <ButtonPrimary href="/login" className="">
                    Sign up
                  </ButtonPrimary>
                </div>
                <ButtonPrimary
                  href="/login"
                  isOutline
                  className="hidden lg:block  "
                >
                  Sign in
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header3;
