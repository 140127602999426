export const GENERAL_CONDITIONS = () => {
  return (
    <div className="text-black mt-10">
      <section className="text-black mb-4">
        <p className="text-black">
          Bienvenue sur notre plateforme de réservation en ligne. En réservant
          un séjour dans notre établissement, vous acceptez pleinement et sans
          réserve les présentes Conditions Générales de Vente. Nous vous
          invitons à lire attentivement ces conditions avant de finaliser votre
          réservation.
        </p>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">1) Validité de l’offre</h5>
        <p className="text-black">
          Les offres de séjour sont proposées sous réserve de disponibilité au
          moment de la réservation. Les descriptifs et tarifs affichés sont
          valables à la date de consultation, sauf erreur ou modification
          intervenant après leur publication. Toute réservation non confirmée
          par le paiement dans les délais spécifiés peut être annulée.
        </p>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">2) Réservations et Paiement</h5>
        <ul className="mt-5">
          <li>
            <p className="text-black">
              <strong>Processus de réservation :</strong> Les réservations sont
              confirmées après paiement d’un acompte ou du montant total, selon
              les conditions affichées lors de la réservation.
            </p>
          </li>
          <li>
            <p className="text-black">
              <strong>Modes de paiement :</strong> Les paiements peuvent être
              effectués en ligne via des moyens sécurisés (carte bancaire,
              plateformes de paiement).
            </p>
          </li>
          <li>
            <p className="text-black">
              <strong>Acompte :</strong> L’acompte versé lors de la réservation
              n’est pas remboursable, sauf exception prévue dans la section
              "Conditions d’annulation".
            </p>
          </li>
          <li>
            <p className="text-black">
              <strong>Solde :</strong> Le solde restant (le cas échéant) doit
              être réglé avant ou pendant le séjour, selon les modalités
              spécifiées.
            </p>
          </li>
        </ul>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">3) Prix</h5>
        <p className="text-black">
          Les tarifs sont exprimés en devise locale et incluent les taxes en
          vigueur au moment de la réservation, sauf mention contraire. Ils ne
          comprennent pas :
        </p>
        <ul>
          <ol>
            - Les dépenses personnelles (boissons, téléphone, services
            supplémentaires)
          </ol>
          <ol>- Les assurances complémentaires</ol>
          <ol>
            - Toute prestation non spécifiquement mentionnée dans la réservation
          </ol>
        </ul>
        <p className="text-black">
          Les tarifs peuvent être révisés en cas d'évolution significative des
          taxes ou taux de change entre la réservation et l’arrivée. Dans ce
          cas, seuls les surcoûts réels seront répercutés.
        </p>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">
          4) Modifications et Annulations
        </h5>
        <ul className="mt-5">
          <li>
            <p className="text-black">
              <strong>Modification de la réservation :</strong> Toute
              modification doit être demandée au moins 7 jours avant la date
              d’arrivée, sous réserve de disponibilité et des conditions
              tarifaires applicables.
            </p>
          </li>
          <li>
            <p className="text-black">
              <strong>Annulation par le client :</strong> Les annulations
              effectuées dans les délais spécifiés lors de la réservation
              peuvent entraîner des frais selon nos politiques affichées. Les
              acomptes versés ne sont pas remboursables.
            </p>
          </li>
          <li>
            <p className="text-black">
              <strong>Annulation par l’établissement :</strong> En cas
              d’annulation par l’établissement, une offre alternative sera
              proposée. Si le client refuse, les sommes versées seront
              remboursées intégralement.
            </p>
          </li>
        </ul>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">5) Check-in / Check-out</h5>
        <p className="text-black">
          Les horaires standards sont indiqués dans le détail de la réservation.
          Des arrangements pour un check-in anticipé ou un check-out tardif
          peuvent être envisagés, sous réserve de disponibilité et de frais
          supplémentaires.
        </p>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">6) Hébergement</h5>
        <p className="text-black">
          Les chambres sont attribuées selon la catégorie réservée. En cas de
          circonstances exceptionnelles, un hébergement de catégorie équivalente
          ou supérieure pourra être proposé sans coût supplémentaire.
        </p>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold  mb-3 d-block">7) Responsabilité</h5>
        <p className="text-black">
          L’établissement décline toute responsabilité pour les pertes, vols,
          accidents ou dommages survenant durant le séjour, sauf en cas de
          négligence avérée de sa part.
        </p>
      </section>

      <section className="text-black mt-5">
        <h5 className="font-bold mb-3 d-block">8) Juridiction</h5>
        <p className="text-black">
          Ces CGV sont régies par la loi tunisienne. En cas de litige, les
          parties s’efforceront de trouver une solution amiable. À défaut, le
          litige sera soumis aux tribunaux compétents.
        </p>
        <p className="text-black">
          Nous vous remercions pour votre confiance et restons à votre
          disposition pour toute information complémentaire.
        </p>
      </section>

      <p className="text-black">Dernière mise à jour le 20 Novembre 2024</p>
    </div>
  );
};
