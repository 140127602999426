export function currentUrlParams() {
  const searchStr = new URL(window.location.href).search;
  return getParams(searchStr);
}

export function getUrlParams(url: string) {
  return getParams(url);
}

function getParams(query: string) {
  const params = query
    .slice(1)
    .split("&")
    .reduce((acc: any, curr) => {
      const [key, value] = curr.split("=");
      acc[key] = value;
      return acc;
    }, {});

  return params;
}
